/*
  Shortanswer
*/
.que.shortanswer {
  label {
    width: 100%;
    font-size: 0;
  }

  .answer {
    display: flex;
    align-items: center;
    font-size: 30px;
  }

  input[type="text"] {
    border: 1px solid $primary;
    background-color: $gray-900;
    border-radius: 4px;
    font-size: 30px;
    color: $primary;
    font-weight: $font-weight-black;
    min-height: 69px;

    &:focus {
      border-color: transparent;
    }
  }

  //Hide default result icon (e.g., check and x)
  input[type="text"] + .icon {
    display: none;
  }

  //Correct
  .answer:has(.correct) {
    color: $brand-correct;
    border-color: $brand-correct;

    &:before {
      content: "\f00c"; // check mark
      font-family: $font-family-icon;
      font-weight: $font-weight-semibold;
      margin-right: 5px;
    }
  }
  input[type="text"].correct {
    color: $brand-correct;
    border-color: $brand-correct;
    border-width: 2px;
    width: 100%;
  }

  //Wrong
  .answer:has(.incorrect) {
    color: $brand-wrong;
    border-color: $brand-wrong;

    &:before {
      content: "\f00d"; // x mark
      font-family: $font-family-icon;
      font-weight: $font-weight-semibold;
      margin-right: 5px;
    }
  }
  input[type="text"].incorrect {
    color: $brand-wrong;
    border-color: $brand-wrong;
    border-width: 2px;
    width: 100%;
  }
}

&#page-mod-quiz-review {
  input[type="text"]:focus {
    box-shadow: none;
  }
}

.welcome-section {
  padding-top: 40px;
  padding-bottom: 30px;
  position: relative;
  background: no-repeat right bottom / cover;

  @media (min-width: 1700px) {
    background: no-repeat center / contain;
  }

  @include media-breakpoint-down(lg) {
    background-position: 80%;
  }

  @include media-breakpoint-down(md) {
    background-position: 60%;
  }

  //borders
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $brand-section-border;
    display: none;
  }

  //background
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    padding-bottom: 15px;
  }
}

.welcome-section__heading {
  color: $primary;
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: center;
  }
}

.welcome-section__heading-small {
  font-size: 20px;
  font-weight: $font-weight-black;
  font-style: italic;
  display: block;
  margin-bottom: 8px;
  color: $black;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    margin-bottom: 4px;
  }
}

.welcome-section__heading-big {
  @include heading-display-big;
  display: block;

  @include media-breakpoint-down(sm) {
    font-size: 11vw;
  }

  > span {
    display: block;
    padding-right: 20px;
  }

  > span:nth-child(2) {
    color: $black;
  }
}

//Styles if heading is a logo
.welcome-section__heading-big {
  //margin-top: 20px;

  > span:nth-child(2) {
    //margin-top: 10px;
  }
}
.welcome-section__heading-big img {
  width: auto;
  height: 51.5px;

  @include media-breakpoint-between(sm, md) {
    height: 41px;
  }

  @include media-breakpoint-down(sm) {
    height: 7.08vw;
  }
}

.welcome-section p {
  margin-top: 20px;
  color: $brand-content-text;

  @include media-breakpoint-down(xl) {
    width: 75%;
  }

  @include media-breakpoint-down(md) {
    width: 50%;
  }

  @include media-breakpoint-down(sm) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: -1;
      filter: blur(35px);
      opacity: 1;
    }
  }
}

.welcome-section .main-inner {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
}

.welcome-section__image {
  width: 100%;
  height: 100%;
  background: no-repeat center/contain;
  display: none !important;

  @include media-breakpoint-down(sm) {
    aspect-ratio: 200 / 150;
    display: none;
  }
}

.welcome-section__heading-image {
  display: none !important;

  @include media-breakpoint-down(sm) {
    display: block;
    flex: 1;
    padding-left: 10px;
  }

  .welcome-section__image {
    @include media-breakpoint-down(sm) {
      display: block;
      background-position: bottom;
    }
  }
}

.welcome-section__cta {
  margin: 20px 0 5px;
}

&#page-user-edit .filepicker {
  .fp-repo-area {
    @include media-breakpoint-down(sm) {
      padding: 5px !important;
    }

    .nav-link {
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

//File Picker
.filepicker {
  .fp-repo .nav-link:not(.active) {
    color: $gray-100;
    border: 1px solid $gray-200;
    background-color: $secondary;
  }

  .fp-repo .nav-link.active {
    color: $gray-100 !important;
    border: 1px solid $gray-200;
    background-color: $primary !important;
  }

  .fp-navbar,
  .fp-setlicense {
    display: none;
  }

  .fp-navbar {
    background-color: transparent;
  }

  .fp-content {
    height: auto;
    padding: 0 0 20px;
    background: $brand-popup-bg;
    border: 1px solid $brand-popup-border;
  }

  .fp-content-center {
    display: flex;
    flex-direction: column;
  }

  .moodle-dialogue-bd {
    min-height: auto;
  }

  .fp-file > div {
    padding: 0 !important;
  }
}

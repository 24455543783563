.notification {
  border-bottom-color: $gray-600;
  border: 1px solid transparent;
  border-radius: $brand-border-radius;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 5px 11px;

  >div[tabindex] {
    outline: none;
  }

  &:hover {
    background-color: rgba($white, 0.05);
  }

  &.unread {
    background-color: $primary;
    color: $white;

    .notification-message,
    .view-more {
      color: $white;
    }

    .timestamp {
      color: $white;
    }

    &:hover {
      border-color: $black;
      color: $white;

      .notification-message,
      .view-more,
      .timestamp {
        color: $white;
      }
    }
  }

  &.unread+.unread {
    margin-top: 2px;
  }

  &:not(.unread) {
    color: $body-color;

    .notification-message,
    .view-more {
      color: $body-color;
    }

    .timestamp {
      color: $gray-500;
    }

    &:hover {
      color: $body-color;

      .notification-message,
      .view-more {
        color: $body-color;
      }

      .timestamp {
        color: $gray-500;
      }
    }
  }

  &.selected {
    background-color: transparent;
    border-color: $white;
  }

  .notification-message {
    font-weight: $font-weight-medium;
  }

  .view-more {
    font-size: 10px;
    font-weight: 400;
    text-decoration: underline;
  }
}

.notification-area {
  background-color: $brand-section-bg;
  border: 1px solid $brand-section-border;
  display: flex;

  @include media-breakpoint-up(lg) {
    height: auto;
  }

  .control-area {
    padding: 10px;
    border: 0;
    float: none;
  }

  .content-area {
    border-left: 1px solid $brand-divider;
    float: none;

    .header {
      border-color: $brand-divider;
      padding: 10px 10px 12px;
      height: auto;
      width: 100%;

      .image-container {
        display: none;
      }

      .subject-container {
        font-size: 20px;
        font-weight: $font-weight-semibold;
        color: $primary;
        height: auto !important;
        padding: 0;
      }

      .timestamp {
        margin: 0;
      }
    }

    >.content {
      height: auto;
    }

    .footer {
      display: none;
    }

    .empty-text {
      display: none !important;
    }
  }

  .toggle-mode {
    border: 0 !important;
    float: none;
    margin-top: 5px;
    margin-left: 10px;
  }
}
.editor_atto_content_wrap {
    background-color: $atto-content-wrap-bg;
    color: $atto-content-wrap-color;
}

.editor_atto_content {
    padding: 4px;
    resize: vertical;
    overflow: auto;
}

.editor_atto_content_wrap,
.editor_atto + textarea {
    width: 100%;
    padding: 0;
}

.editor_atto + textarea {
    border-radius: 0;
    resize: vertical;
    margin-top: -1px;
}

div.editor_atto_toolbar {
    display: block;
    background: $atto-toolbar-bg;
    min-height: 35px;
    border: 1px solid $input-border-color;
    width: 100%;
    padding: 0 0 9px 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

div.editor_atto_toolbar button {
    padding: 4px 9px;
    background: none;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
}

div.editor_atto_toolbar .menuplaceholder {
    display: inline-block;
}

div.editor_atto_toolbar {
    button + button,
    .menuplaceholder + button {
        border-left: 1px solid $atto-toolbar-border-color;
    }
}

div.editor_atto_toolbar button[disabled] {
    opacity: .45;
    background: none;
    cursor: default;
}

.editor_atto_toolbar button:hover {
    background-image:
        radial-gradient(
            ellipse at center,
            $atto-toolbar-button-gradient-inner 60%,
            $atto-toolbar-button-gradient-outer 100%
        );
    background-color: $atto-toolbar-button-hover-bg;
}

.editor_atto_toolbar button:active,
.editor_atto_toolbar button.highlight {
    background-image:
        radial-gradient(
            ellipse at center,
            $atto-toolbar-button-gradient-inner 40%,
            $atto-toolbar-button-gradient-outer 100%
        );
    background-color: $atto-toolbar-button-active-bg;
}

/* Make firefox button sizes match other browsers */
div.editor_atto_toolbar button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

div.editor_atto_toolbar button .icon {
    padding: 0;
    margin: 2px 0;
}

div.editor_atto_toolbar div.atto_group {
    display: inline-block;
    border: 1px solid $atto-toolbar-border-color;
    border-bottom: 1px solid $atto-toolbar-group-border-bottom-color;
    border-radius: 4px;
    margin: 9px 0 0 9px;
    background: $atto-toolbar-group-bg;
}

div.editor_atto_toolbar .atto_toolbar_row {
    margin: 6px 0 -3px 5px;
    display: table;
    div.atto_group {
        margin: 3px 5px 3px 4px;
    }
}

.editor_atto_content img {
    resize: both;
    overflow: auto;
}

.atto_hasmenu {
    /* IE8 places the images on top of each other if that is not set. */
    white-space: nowrap;
}

.atto_menuentry .icon {
    width: 16px;
    height: 16px;
}

.atto_menuentry {
    clear: left;
}

.atto_menuentry h1,
.atto_menuentry h2,
.atto_menuentry p {
    margin: 4px;
}

/*.atto_form label.sameline {
    display: inline-block;
    min-width: 10em;
}*/

.atto_form textarea.fullwidth,
.atto_form input.fullwidth {
    width: 100%;
}

.atto_form {
    padding: 0.5rem;
}

/*.atto_form label {
    display: block;
    margin: 0 0 5px 0;
}*/

.atto_control {
    position: absolute;
    right: -6px;
    bottom: -6px;
    display: none;
    cursor: pointer;
}

.atto_control .icon {
    background-color: $atto-control-icon-bg;
}

div.editor_atto_content:focus .atto_control,
div.editor_atto_content:hover .atto_control {
    display: block;
}

.editor_atto_menu.yui3-menu-hidden {
    display: none;
}

/* Get broken images back in firefox */
.editor_atto_content img:-moz-broken {
    -moz-force-broken-image-icon: 1;
    min-width: 24px;
    min-height: 24px;
}

/* Atto menu styling */
.moodle-dialogue-base .editor_atto_menu .moodle-dialogue-content .moodle-dialogue-bd {
    padding: 0;
    z-index: 1000;
}

.editor_atto_menu .dropdown-menu > li > a {
    margin: 3px 14px;
}

.editor_atto_menu .open ul.dropdown-menu {
    padding-top: 5px;
    padding-bottom: 5px;
}

.editor_atto_wrap {
    position: relative;
}
/*rtl:ignore*/
.editor_atto_wrap textarea {
    direction: ltr;
}

.editor_atto_notification {
    display: inline-block;
    padding: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;

    .atto_info {
        background-color: $atto-notif-info;
    }

    .atto_warning {
        background-color: $atto-notif-warning;
    }
}

.editor_atto_toolbar,
.editor_atto_content_wrap,
.editor_atto + textarea {
    box-sizing: border-box;
}

.editor_atto_content.form-control {
    width: 100%;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/** Atto fields do not have form-control because that would break the layout of the editor.
    So they need these extra styles to highlight the editor when there is a validation error. */
.has-danger .editor_atto_content.form-control,
.has-danger .editor_atto_content.form-control-danger {
    @include form-validation-state('invalid', $form-feedback-invalid-color, $form-feedback-icon-invalid);
}

.open.atto_menu > .dropdown-menu {
    display: block;
}
div.editor_atto_toolbar button .icon {
    color: $atto-toolbar-button-color;
}

.courseindex {
  .courseindex-section {
    border-left: 0;

    &:not(:first-child) {
      .courseindex-section-title {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 0;
          border-top: 1px dashed $brand-divider;
        }
      }
    }
  }

  .courseindex-item {
    border-radius: 0;

    &:hover,
    &:focus {
      .courseindex-link,
      .courseindex-chevron {
        color: $gray-100;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 5px;
      width: calc(100% - 10px);
      height: 100%;
      border: 1px solid $white;
      border-radius: $brand-border-radius;
      opacity: 0;
      z-index: -1;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &.pageitem {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:before {
        background-color: $primary;
        border: 0;
        opacity: 1;
      }

      .courseindex-chevron:focus {
        color: $brand-text-black;
      }

      .courseindex-link {
        color: $gray-100 !important;
      }
    }

    &.courseindex-section-title {
      padding-left: 25px;
      padding-right: 25px;
      text-transform: uppercase;
    }

    .courseindex-link,
    .courseindex-chevron {
      color: $gray-100;
      padding-left: 15px;

      &:hover,
      &:focus {
        color: $gray-100;
      }
    }

    //Disables section page navigation and extend the boundary of the accordion toggle
    position: relative;
    a[data-toggle="collapse"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .courseindex-link[data-action="togglecourseindexsection"] {
      pointer-events: none;
      padding-left: 15px;
    }
  }

  //Remove announcements on sidebar
  .courseindex-item:has(a[href*="/mod/forum"]){
    display: none !important;
  }

  .courseindex-section .courseindex-sectioncontent {
    .courseindex-item {
      padding-left: 35px;

      &:hover {
        .completion_incomplete {
          color: $body-color;
        }
      }

      .courseindex-link {
        padding-left: 0;
      }

      .completion_complete + .courseindex-link {
        color: $brand-correct;
      }

      .completion_fail + .courseindex-link {
        color: $brand-wrong;
      }
    }
  }
}

.js-lightbox > * {
  cursor: pointer;
}

.sl-overlay {
  outline: 0 !important;
  opacity: 0.95 !important;
}

.sl-wrapper .sl-counter {
  top: 25px;
  left: unset;
  right: 30px;
  font-weight: bold;
  color: $white;
  background-color: $brand-text-black;
  border-radius: 4px;
  padding: 5px 20px;
}

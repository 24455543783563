//Styling adjustments for admin site
& {
  .card-img.dashboard-card-img {
    > div {
      background: no-repeat center/cover;
      aspect-ratio: 16 / 9;
    }
  }
  .dashboard-card-deck .dashboard-card .dashboard-card-img {
    height: auto;
  }

  .activity-altcontent img {
    max-height: 300px;
  }

  .customfieldvalue img {
    max-height: 300px;
  }

  .dashboard-card__main-badge img{
    width: 100%;
    max-height: 300px;
  }
}

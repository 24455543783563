/**
 Deprecations
 */

//
// Deprecation mixin.
//
@mixin deprecated-styles() {
    /* stylelint-disable declaration-no-important */
    body.behat-site &,
    body.behat-site#{&},
    body.themedesignermode &,
    body.themedesignermode#{&} {
        outline: 3px dotted $red !important;
        background-color: lighten($red, 50%) !important;
        &::before {
            content: "Deprecated style in use" !important;
            color: $red !important;
            font-size: smaller !important;
        }
    }
    /* stylelint-enable */
}

//
// Deprecated styles.
//

// All deprecated styles should include "deprecated-styles" mixin.
// Example:
// .foo {
//     .bar {
//         color: $blue;
//         @include deprecated-styles();
//     }
//     color: $red;
//     @include deprecated-styles();
// }

//
// Deprecated since Moodle 4.4.
//

// The .togglecompletion usage was deprecated in 311 and finally removed in 403.
// Replaced by core_renderer::activity_information() and proper use of templates.
.path-course-view li.activity form.togglecompletion {
    @include deprecated-styles();
}
.path-course-view li.activity form.togglecompletion .ajaxworking {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 22px;
    top: 3px;
    //background: url([[pix:i/ajaxloader]]) no-repeat;
}
.path-course-view li.activity form.togglecompletion .btn {
    padding: 0;
}
.path-course-view li.activity form.togglecompletion img {
    max-width: none; /* The width is 0 so ensure we don't end up with a relative max-width */
}

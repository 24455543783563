.singlebutton {
    display: inline-block;

    + .singlebutton {
        margin-left: $spacer * 0.5;
    }
}

.continuebutton {
    text-align: center;
}

p.arrow_button {
    margin-top: 5em;
    text-align: center;
}

#addcontrols {
    // This is displayed in a column between 2 20 row multi-selects. This should be just short of half way.
    margin-top: 8 * $line-height-base * $font-size-base;
    text-align: center;
    margin-bottom: 3em;

    label {
        display: inline;
    }
}

#addcontrols,
#removecontrols {
    input {
        width: 100%;
        margin: auto;
    }
}

.btn-lineup {
    margin: 0 0 10px 5px;
}

.btn.btn-icon {
    height: ($icon-width + 20px);
    width: ($icon-width + 20px);
    font-size: $icon-width;
    line-height: $icon-width;
    padding: 0;
    border-radius: 50%;
    flex-shrink: 0;

    @include hover-focus {
        background-color: $gray-200;
    }

    @each $size, $length in $iconsizes {
        &.icon-size-#{$size} {
            height: ($length + 20px) !important; /* stylelint-disable-line declaration-no-important */
            width: ($length + 20px) !important; /* stylelint-disable-line declaration-no-important */
            font-size: $length !important; /* stylelint-disable-line declaration-no-important */
            line-height: $length !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

@mixin button-focus($background, $innershadow) {
    &:focus,
    &.focus {
        outline: $btn-focus-width solid darken($background, 40%);
        box-shadow: inset 0 0 0 2px $innershadow;
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-focus($value, $white);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-focus($value, $gray-800);
    }
}

.block_news_items {
  .card-text {
    display: flex;
    flex-direction: column;
    //height: calc(100% - 50px);
    max-height: calc(100px * 3);
    overflow: auto;

    scrollbar-color: $primary $gray-900;
    scrollbar-width: thin;
  }

  .unlist {
    padding: 0 10px;
    flex: 1;
  }
  .unlist .post {
    padding: 10px 0;

    &:not(:first-child) {
      border-top: 1px solid $gray-800;
    }

    .head {
      display: flex;
    }

    .name {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: $brand-content-text;
      order: 0;
      display: none;
    }

    .date {
      font-size: 12px;
      color: $brand-text-gray;
      order: 1;
      display: flex;
      align-items: center;

      //bullet divider
      &:before {
        content: '';
        width: 3px;
        height: 3px;
        background: currentColor;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 8px;
        display: none;
      }
    }

    .info {
      font-size: 1.125rem;
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    .info a:not(:focus) {
      color: $white;
    }
  }

  .footer {
    padding: 0 10px;
    margin: 10px 0 5px;
    font-size: 12px;
    text-align: right;
  }
}

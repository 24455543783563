.popover {
  padding: 10px 20px;

  .popover-body {
    color: $brand-text-black;
    padding: 0;
    margin: 0;

    * {
      //border: 1px solid red;
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }

    ul:first-child {
      margin: 0;
      padding: 0;
      list-style-type: none;

      > li {
        margin: 5px 0;
      }
    }

    > *:first-child {
      margin: 0;
      padding: 0;

      > *:first-child {
        margin: 0;
      }
    }
  }
}

&#page-mod-forum-discuss {
    div[data-content="forum-discussion"] {

        //Hide forum dropdowns
        >*:not(article) {
            display: none !important;
        }
    }

    .discussionname {
        display: none;
    }
}
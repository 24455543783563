&#page-mod-forum-view {
  #region-main {
    >h2 {
      display: none;
    }

    >.activity-header {
      display: none;
    }
  }

  .tertiary-navigation {
    display: none;
  }
}
.popover-region {
  .popover-region-toggle:focus {
    box-shadow: none;
  }
}

.popover-region.popover-region-notifications {
  transition: none;

  .popover-region-container {
    transition-duration: 0s !important;
    transition-delay: 0s !important;

    @include media-breakpoint-down(sm) {
      inset: 60px 0 0;
    }
  }

  .popover-region-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: rgba($white, 0.1);
  }

  .popover-region-content-container {
    @include media-breakpoint-down(sm) {
      height: calc(100% - 67px);
    }
  }

  .popover-region-content {
    max-height: 400px;
  }

  .popover-region-toggle {
    color: $body-color;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .popover-region-toggle::before {
    border-bottom-color: rgba($black, 0.1);
  }
  .popover-region-toggle::after {
    border-bottom-color: $gray-100;
  }

  .popover-region-header-text {
    color: $primary;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-black;
    margin: 0;
    margin-left: 5px;
  }

  .popover-region-header-actions .icon {
    font-size: 12px;
  }

  .popover-region-container {
    background-color: $brand-popup-bg;
    border-color: $brand-popup-border;
    height: auto;
  }

  .popover-region-footer-container {
    height: 30px;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: $brand-popup-footer-bg;
    padding-top: 4px;
    font-size: 12px;
  }

  .content-item-container.unread {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .all-notifications {
    padding: 5px 5px 10px;
  }
}

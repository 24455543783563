.block__footer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 5px;
  padding: 0 10px;
}

.block__anchor {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: $font-weight-semibold;
  color: #cfd0d0;
  text-transform: uppercase;
  text-decoration: none !important;
  border-bottom: 1px solid $brand-text-gray;

  &:hover {
    border-color: currentColor;
  }
}

.block_badges .block__anchor {
  color: $primary;
  border-color: $primary;

  &:hover {
    color: darken($primary, 10%);
  }
}

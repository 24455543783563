.que {
  margin: 80px 0 0;

  @include media-breakpoint-down(sm) {
    margin: 40px 0 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:not(:first-child) {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -40px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $brand-divider;
    }
  }
}

.que .info {
  display: none;
}

.que .content {
  margin: 0;
}

.que .formulation {
  background-color: transparent;
  color: $body-color;
  padding: 0;
}

//Adds question numbers to questions
form[action*="quiz"] > div {
  counter-reset: question-number;
}
.que {
  counter-increment: question-number;
}
.que .qtext {
  @include media-breakpoint-down(sm) {
    margin-bottom: 1rem;
  }
}
.que .qtext p {
  font-size: 40px;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  pointer-events: none;
  display: flex;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }

  * {
    color: $body-color;
  }

  &:before {
    content: counter(question-number) ". ";
    margin-right: 10px;
  }
}

/*
Question Answers Custom Styling
*/
.answernumber {
  display: none;
}

.que .outcome {
  background-color: transparent;
  color: $brand-content-text;

  .rightanswer {
    font-weight: $font-weight-semibold;
  }

  .rightanswer img {
    object-fit: contain;
    max-height: 300px;
    width: auto;
    display: block;
  }
}

.answer + div[class*="clearchoice"] {
  display: none;
  pointer-events: none;
}

.que.correct .specificfeedback {
  color: $brand-correct;
}
.que.notanswered .specificfeedback,
.que.incorrect .specificfeedback {
  color: lighten($brand-wrong, 10%);

  + .rightanswer {
    img {
      margin-top: 10px;
    }
  }
}

.que.correct {
  .rightanswer {
    display: none;
  }
}

/* core.less */

$bg-inverse-link-color: $white !default;

$dropzone-border: $gray-900 !default;

$collapse-list-item-padding-y: 0.5rem !default;
$collapse-list-item-padding-x: 1rem !default;
$collapse-list-item-hover-bg: theme-color-level('info', -11) !default;
$collapse-list-item-hover-border: theme-color-level('info', -9) !default;

$thin-scroll-bg-thumb: $gray-600 !default;
$thin-scroll-bg-hover: $gray-700 !default;

$font-size-xs: ($font-size-base * .75) !default;

#region-main {
    overflow-y: visible;
    background-color: $body-bg;
}

@include media-breakpoint-up(sm) {
    .context-header-settings-menu,
    .region-main-settings-menu {
        float: right;
        width: auto;
        max-width: 4em;
        height: 2em;
        display: block;
        margin-top: 4px;
    }
}

@include media-breakpoint-down(sm) {
    .context-header-settings-menu,
    .region-main-settings-menu {
        display: flex;
        justify-content: flex-end;
    }
}

@mixin optional-animation($animation) {
    animation: $animation;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }

    body.behat-site & {
        animation: none;
    }
}

.context-header-settings-menu .dropdown-toggle > .icon,
#region-main-settings-menu .dropdown-toggle > .icon {
    height: 24px;
    font-size: 24px;
    width: auto;
}
/** Prevent user notifications overlapping with region main settings menu */
#user-notifications {
    display: block;
    overflow: hidden;
}

/** Page layout CSS starts **/
.layout-option-noheader #page-header,
.layout-option-nonavbar #page-navbar,
.layout-option-nofooter #page-footer,
.layout-option-nocourseheader .course-content-header,
.layout-option-nocoursefooter .course-content-footer {
    display: none;
}

/** Page layout CSS ends **/

.mdl-left {
    text-align: left;
}

.mdl-right {
    text-align: right;
}

/*rtl:ignore*/
.text-ltr {
    direction: ltr !important; /* stylelint-disable-line declaration-no-important */
}

#add,
#remove,
.centerpara,
.mdl-align {
    text-align: center;
}

a.dimmed,
a.dimmed:link,
a.dimmed:visited,
a.dimmed_text,
a.dimmed_text:link,
a.dimmed_text:visited,
.dimmed_text,
.dimmed_text a,
.dimmed_text a:link,
.dimmed_text a:visited,
.usersuspended,
.usersuspended a,
.usersuspended a:link,
.usersuspended a:visited,
.dimmed_category,
.dimmed_category a {
    color: $text-muted;
}

// Accessible focus styling for links, add .aalink to links with custom css classes to get
// accessible focus styles.
.aalink,
#page-footer a:not([class]),
.arrow_link,
a:not([class]),
.activityinstance > a {
    &.focus,
    &:focus {
        outline: $btn-focus-width solid transparent;
        color: $gray-900;
        background-color: lighten($primary, 50%);
        box-shadow: 0 -#{$btn-focus-width} lighten($primary, 50%), 0 $btn-focus-width $gray-800;
    }
    &:focus:hover {
        text-decoration: none;
    }
}

// Accessible focus styling for buttons like elements that do not use the .btn class. Add
// .aabtn to you element if you need the same focus styles.
.aabtn,
.btn-link,
.nav-link,
.editor_atto_toolbar button,
.editor_atto_toolbar .atto_toolbar_row,
[role="button"],
.list-group-item-action,
input[type="checkbox"],
input[type="radio"],
input[type="file"],
input[type="image"],
.sr-only-focusable,
a.dropdown-toggle,
.moodle-dialogue-base .closebutton,
button.close,
.form-autocomplete-selection,
[role="treeitem"]:not([aria-expanded="true"]) {
    &.focus,
    &:focus {
        outline: 0;
        box-shadow: $input-btn-focus-box-shadow;
    }
    &:focus:hover {
        text-decoration: none;
    }
}

// Modal dialogs border should be rounded too.
.modal-dialog[tabindex="0"] {
    &.focus,
    &:focus {
        outline: 0;
        .modal-content {
            outline: 0;
            box-shadow: $input-btn-focus-box-shadow;

            // Modal dialogs border should be rounded too.
            @include border-radius($modal-content-border-radius);
        }
    }
}

// We don't want to highlight the whole li when it's expanded. Only the first child is highlighted.
[role="treeitem"][aria-expanded="true"] {
    outline: 0;
    &.focus,
    &:focus {
        > *:first-child {
            outline: 0;
            box-shadow: $input-btn-focus-box-shadow;
        }
    }
    &:focus:hover {
        text-decoration: none;
    }
}

// Accessible focus styling for autocomplete elements.
.form-autocomplete-suggestions li[aria-selected=true] {
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;
}

// Safari does not allow custom styling of checkboxes.
.safari {
    input[type="checkbox"],
    input[type="radio"] {
        &.focus,
        &:focus {
            outline: auto;
        }
    }
}

.unlist,
.unlist li,
.inline-list,
.inline-list li,
.block .list,
.block .list li,
.section li.movehere,
.tabtree li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.section li.movehere a {
    display: block;
    width: 100%;
    height: 2rem;
    border: 2px dashed $gray-800;
}

.editing .course-content .hidden.sectionname {
    visibility: hidden;
    display: initial;
}

.inline,
.inline-list li {
    display: inline;
}

.notifytiny {
    font-size: $font-size-xs;
}

.notifytiny li,
.notifytiny td {
    font-size: 100%;
}

.red,
.notifyproblem {
    color: map-get($theme-colors, 'warning');
}

.green,
.notifysuccess {
    color: map-get($theme-colors, 'success');
}

.highlight {
    color: map-get($theme-colors, 'info');
}

.bg-primary-light {
    background-color: $primary-light-background;
}

.fitem.advanced .text-info {
    font-weight: bold;
}

.reportlink {
    text-align: right;
}

a.autolink {
    @extend .aalink;
    &.glossary:hover {
        cursor: help;
    }
}
/* Block which is hidden if javascript enabled, prevents fickering visible when JS from footer used! */
.collapsibleregioncaption {
    white-space: nowrap;
    min-height: $line-height-base * $font-size-base;
}

.pagelayout-mydashboard.jsenabled .collapsibleregioncaption {
    cursor: pointer;
}

.pagelayout-mydashboard #region-main {
    border: 0;
    padding: 0;
    background-color: transparent;
    margin-top: -1px;
}

@include media-breakpoint-down(sm) {
    .pagelayout-mydashboard,
    .pagelayout-login {
        #region-main-box {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.collapsibleregioncaption img {
    vertical-align: middle;
}

.jsenabled .hiddenifjs {
    display: none;
}

.visibleifjs {
    display: none;
}

.jsenabled .visibleifjs {
    display: inline;
}

.jsenabled .collapsibleregion {
    overflow: hidden;
    box-sizing: content-box;
}

.jsenabled .collapsed .collapsibleregioninner {
    visibility: hidden;
}

.collapsible-actions {
    display: none;
    text-align: right;
}

.jsenabled .collapsible-actions {
    display: block;
}

.yui-overlay .yui-widget-bd {
    background-color: $yui-overlay-bg;
    border: 1px solid $yui-overlay-border-color;
    border-top-color: $yui-overlay-border-top-color;
    color: $yui-overlay-color;
    left: 0;
    padding: 2px 5px;
    position: relative;
    top: 0;
    z-index: 1;
}

.clearer {
    background: transparent;
    border-width: 0;
    clear: both;
    display: block;
    height: 1px;
    margin: 0;
    padding: 0;
}

.bold,
.warning,
.errorbox .title,
.pagingbar .title,
.pagingbar .thispage {
    font-weight: bold;
}

img.resize {
    height: 1em;
    width: 1em;
}

.block img.resize {
    height: 0.9em;
    width: 0.8em;
}
/* Icon styles */
img.activityicon {
    height: 24px;
    width: 24px;
    vertical-align: middle;
}

.headermain {
    font-weight: bold;
}

#maincontent {
    display: block;
    height: 1px;
    overflow: hidden;
}

img.uihint {
    cursor: help;
}

#addmembersform table {
    margin-left: auto;
    margin-right: auto;
}

table.flexible .emptyrow {
    display: none;
}

form.popupform,
form.popupform div {
    display: inline;
}

.arrow_button input {
    overflow: hidden;
}
.no-overflow {
    overflow: auto;
}

// Position required for table sizing inside a flex container.
.no-overflow > .generaltable {
    margin-bottom: 0;
}

.no-overflow,
.table-responsive {
    .generaltable {
        .sr-only,
        .accesshide {
            position: relative;
            display: block;
        }
    }
}

// Accessibility features

// Accessibility: text 'seen' by screen readers but not visual users.
.accesshide {
    @include sr-only();
}

span.hide,
div.hide,
.hidden {
    display: none;
}
// Accessibility: Skip block link, for keyboard-only users.
a.skip-block,
a.skip {
    position: absolute;
    top: -1000em;
    font-size: 0.85em;
    text-decoration: none;
}

a.skip-block:focus,
a.skip-block:active,
a.skip:focus,
a.skip:active {
    position: static;
    display: block;
}

.skip-block-to {
    display: block;
    height: 1px;
    overflow: hidden;
}
// Blogs
.addbloglink {
    text-align: center;
}

.blog_entry .audience {
    text-align: right;
    padding-right: 4px;
}

.blog_entry .tags {
    margin-top: 15px;
}

.blog_entry .content {
    margin-left: 43px;
}
// Group

#doc-contents h1 {
    margin: 1em 0 0 0;
}

#doc-contents ul {
    margin: 0;
    padding: 0;
    width: 90%;
}

#doc-contents ul li {
    list-style-type: none;
}

.groupmanagementtable td {
    vertical-align: top;
}

.groupmanagementtable #existingcell,
.groupmanagementtable #potentialcell {
    width: 42%;
}

.groupmanagementtable #buttonscell {
    width: 16%;
}

.groupmanagementtable #buttonscell p.arrow_button input {
    width: auto;
    min-width: 80%;
    margin: 0 auto;
    display: block;
}

.groupmanagementtable #removeselect_wrapper,
.groupmanagementtable #addselect_wrapper {
    width: 100%;
}

.groupmanagementtable #removeselect_wrapper label,
.groupmanagementtable #addselect_wrapper label {
    font-weight: normal;
}

#group-usersummary {
    width: 14em;
}

.groupselector {
    margin-top: 3px;
    margin-bottom: 3px;
    display: inline-block;
}

.groupselector label {
    display: inline-block;
}

// Notes
.notepost {
    margin-bottom: 1em;
}

.notepost .userpicture {
    float: left;
    margin-right: 5px;
}

.notepost .content,
.notepost .footer {
    clear: both;
}

.notesgroup {
    margin-left: 20px;
}

// My Moodle
.path-my .coursebox {
    margin: $spacer 0;
    padding: 0;

    .overview {
        margin: 15px 30px 10px 30px;
    }
}

.path-my .coursebox .info {
    float: none;
    margin: 0;
}

// Modules
.mod_introbox {
    padding: 10px;
}

table.mod_index {
    width: 100%;
}

// Comments
.comment-ctrl {
    font-size: 12px;
    display: none;
    margin: 0;
    padding: 0;
}

.comment-ctrl h5 {
    margin: 0;
    padding: 5px;
}

.comment-area {
    max-width: 400px;
    padding: 5px;
}

.comment-area textarea {
    width: 100%;
    overflow: auto;

    &.fullwidth {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.comment-area .fd {
    text-align: right;
}

.comment-meta span {
    color: $comment-meta-color;
}

.comment-link img {
    vertical-align: text-bottom;
}

.comment-list {
    font-size: 11px;
    overflow: auto;
    list-style: none;
    padding: 0;
    margin: 0;
}

.comment-list li {
    margin: 2px;
    list-style: none;
    margin-bottom: 5px;
    clear: both;
    padding: .3em;
    position: relative;
}

.comment-list li.first {
    display: none;
}

.comment-paging {
    text-align: center;
}

.comment-paging .pageno {
    padding: 2px;
}

.comment-paging .curpage {
    border: 1px solid $comment-paging-current-border-color;
}

.comment-message .picture {
    float: left;
    margin-right: 0.25rem;
}

.comment-message .text {
    margin: 0;
    padding: 0;
}

.comment-message .text p {
    padding: 0;
    margin: 0 18px 0 0;
}

.comment-delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: .3em;
}

.comment-report-selectall {
    display: none;
}

.comment-link {
    display: none;
}

.jsenabled .comment-link {
    display: block;
}

.jsenabled .showcommentsnonjs {
    display: none;
}

.jsenabled .comment-report-selectall {
    display: inline;
}
/**
* Completion progress report
*/
.completion-expired {
    color: map-get($theme-colors, 'warning');
}

.completion-expected {
    font-size: $font-size-xs;
}

.completion-sortchoice,
.completion-identifyfield {
    font-size: $font-size-xs;
    vertical-align: bottom;
}

.completion-progresscell {
    text-align: right;
}

.completion-expired .completion-expected {
    font-weight: bold;
}
/**
* Tags
*/
img.user-image {
    height: 100px;
    width: 100px;
}

#tag-search-box {
    text-align: center;
    margin: 10px auto;
}

.path-tag .tag-index-items .tagarea {
    border: 1px solid $tags-tagarea-border-color;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}

.path-tag .tag-index-items .tagarea h3 {
    display: block;
    padding: 3px 0 10px 0;
    margin: 0;
    font-size: 1.1em;
    font-weight: bold;
    line-height: 20px;
    color: $tags-tagarea-title-color;
    text-shadow: 0 1px 0 $tags-tagarea-title-text-shadow-color;
    text-transform: uppercase;
    word-wrap: break-word;
    border-bottom: solid 1px $tags-tagarea-title-border-color;
    margin-bottom: 10px;
}

.path-tag .tagarea .controls,
.path-tag .tagarea .taggeditems {
    @include clearfix();
}

.path-tag .tagarea .controls,
.path-tag .tag-backtoallitems {
    text-align: center;
}

.path-tag .tagarea .controls .gotopage.nextpage {
    float: right;
}

.path-tag .tagarea .controls .gotopage.prevpage {
    float: left;
}

.path-tag .tagarea .controls .exclusivemode {
    display: inline-block;
}

.path-tag .tagarea .controls.controls-bottom {
    margin-top: 5px;
}

.path-tag .tagarea .controls .gotopage.nextpage::after {
    padding-right: 5px;
    padding-left: 5px;
    content: "»";
}

.path-tag .tagarea .controls .gotopage.prevpage::before {
    padding-right: 5px;
    padding-left: 5px;
    content: "«";
}

span.flagged-tag,
tr.flagged-tag,
span.flagged-tag a,
tr.flagged-tag a {
    color: map-get($theme-colors, 'warning');
}

.tag-management-table td,
.tag-management-table th {
    vertical-align: middle;
    padding: 4px;
}

.tag-management-table .inplaceeditable.inplaceeditingon input {
    width: 150px;
}

.path-admin-tag .addstandardtags {
    float: right;

    img {
        margin: 0 5px;
    }
}

.path-tag .tag-relatedtags {
    padding-top: 10px;
}

.path-tag .tag-management-box {
    text-align: right;
}

.path-tag .tag-index-toc {
    padding: 10px;
    text-align: center;
}

.path-tag .tag-index-toc li,
.path-tag .tag-management-box li {
    margin-left: 5px;
    margin-right: 5px;
}

.path-tag .tag-management-box li a.edittag {
    //background-image: url([[pix:moodle|i/settings]]);
}

.path-tag .tag-management-box li a.flagasinappropriate {
    //background-image: url([[pix:moodle|i/flagged]]);
}

.path-tag .tag-management-box li a.removefrommyinterests {
    //background-image: url([[pix:moodle|t/delete]]);
}

.path-tag .tag-management-box li a.addtomyinterests {
    //background-image: url([[pix:moodle|t/add]]);
}

.path-tag .tag-management-box li a {
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 17px;
}

.tag_feed.media-list .media .itemimage {
    float: left;
}

.tag_feed.media-list .media .itemimage img {
    height: 35px;
    width: 35px;
}

.tag_feed.media-list .media .media-body {
    padding-right: 10px;
    padding-left: 10px;
}

.tag_feed .media .muted a {
    color: $text-muted;
}

.tag_cloud {
    text-align: center;
}

.tag_cloud .inline-list li {
    padding: 0 0.2em;
}

.tag_cloud .tag_overflow {
    margin-top: 1em;
    font-style: italic;
}

.tag_cloud .s20 {
    font-size: 2.7em;
}

.tag_cloud .s19 {
    font-size: 2.6em;
}

.tag_cloud .s18 {
    font-size: 2.5em;
}

.tag_cloud .s17 {
    font-size: 2.4em;
}

.tag_cloud .s16 {
    font-size: 2.3em;
}

.tag_cloud .s15 {
    font-size: 2.2em;
}

.tag_cloud .s14 {
    font-size: 2.1em;
}

.tag_cloud .s13 {
    font-size: 2em;
}

.tag_cloud .s12 {
    font-size: 1.9em;
}

.tag_cloud .s11 {
    font-size: 1.8em;
}

.tag_cloud .s10 {
    font-size: 1.7em;
}

.tag_cloud .s9 {
    font-size: 1.6em;
}

.tag_cloud .s8 {
    font-size: 1.5em;
}

.tag_cloud .s7 {
    font-size: 1.4em;
}

.tag_cloud .s6 {
    font-size: 1.3em;
}

.tag_cloud .s5 {
    font-size: 1.2em;
}

.tag_cloud .s4 {
    font-size: 1.1em;
}

.tag_cloud .s3 {
    font-size: 1em;
}

.tag_cloud .s2 {
    font-size: 0.9em;
}

.tag_cloud .s1 {
    font-size: 0.8em;
}

.tag_cloud .s0 {
    font-size: 0.7em;
}

.tag_list ul {
    display: inline;
}

.tag_list.hideoverlimit .overlimit {
    display: none;
}

.tag_list .tagmorelink {
    display: none;
}

.tag_list.hideoverlimit .tagmorelink {
    display: inline;
}

.tag_list.hideoverlimit .taglesslink {
    display: none;
}

/**
* Web Service
*/
#webservice-doc-generator td {
    text-align: left;
    border: 0 solid $webservice-doc-td-border-color;
}

/**
* Enrol
*/
.userenrolment {
    width: 100%;
    border-collapse: collapse;
}

.userenrolment tr {
    vertical-align: top;
}

.userenrolment td {
    padding: 0;
    height: 41px;
}

.userenrolment .subfield {
    margin-right: 5px;
}

.userenrolment .col_userdetails .subfield {
    margin-left: 40px;
}

.userenrolment .col_userdetails .subfield_picture {
    float: left;
    margin-left: 0;
}

.userenrolment .col_lastseen {
    width: 150px;
}

.userenrolment .col_role {
    width: 262px;
}

.userenrolment .col_role .roles,
.userenrolment .col_group .groups {
    margin-right: 30px;
}

.userenrolment .col_role .role {
    float: left;
    padding: 0 3px 3px;
    margin: 0 3px 3px;
    white-space: nowrap;
}

.userenrolment .col_group .group {
    float: left;
    padding: 3px;
    margin: 3px;
    white-space: nowrap;
}

.userenrolment .col_role .role a,
.userenrolment .col_group .group a {
    margin-left: 3px;
    cursor: pointer;
}

.userenrolment .col_role .addrole,
.userenrolment .col_group .addgroup {
    float: right;
    padding: 3px;
    margin: 3px;

    > a:hover {
        border-bottom: 1px solid $userenrolment-link-hover-border-color;
    }
}

.userenrolment .col_role .addrole img,
.userenrolment .col_group .addgroup img {
    vertical-align: baseline;
}

.userenrolment .hasAllRoles .col_role .addrole {
    display: none;
}

.userenrolment .col_enrol .enrolment {
    float: left;
    padding: 0 3px 3px;
    margin: 0 3px 3px;
}

.userenrolment .col_enrol .enrolment a {
    float: right;
    margin-left: 3px;
}

#page-enrol-otherusers .userenrolment .col_role {
    .role {
        float: none;
        margin: 3px 3px 3px 0;
        padding: 3px 3px 3px 0;
    }
}

.corelightbox {
    background-color: $corelightbox-bg;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}

.corelightbox img {
    position: fixed;
    top: 50%;
    left: 50%;
}

.mod-indent-outer {
    display: table;
}

.mod-indent {
    display: table-cell;
}

.label .mod-indent {
    float: left;
    padding-top: 20px;
}

.activity.label.modtype_label .mod-indent {
    float: none;
}

@include media-breakpoint-up(sm) {
    $mod-indent-size: 30px;
    /* Creates a series of .mod-indent-# rule declarations based on indent size and number of indent levels. */

    @for $i from 1 through 16 {
        $width: ($i * $mod-indent-size);

        .mod-indent-#{$i} {
            width: $width;
        }
    }

    .mod-indent-huge {
        width: (16 * $mod-indent-size);
    }
}

/* Audio player size in 'block' mode (can only change width, height is hardcoded in JS) */
.resourcecontent .mediaplugin_mp3 object {
    height: 25px;
    width: 600px;
}

.resourcecontent audio.mediaplugin_html5audio {
    width: 600px;
}
/** Large resource images should avoid hidden overflow **/
.resourceimage {
    max-width: 100%;
}
/* Audio player size in 'inline' mode (can only change width, as above) */
.mediaplugin_mp3 object {
    height: 15px;
    width: 300px;
}

audio.mediaplugin_html5audio {
    width: 300px;
}

.core_media_preview.pagelayout-embedded #maincontent {
    height: 0;
}

.path-rating .ratingtable {
    width: 100%;
    margin-bottom: 1em;
}

.path-rating .ratingtable th.rating {
    width: 100%;
}

.path-rating .ratingtable td.rating,
.path-rating .ratingtable td.time {
    white-space: nowrap;
    text-align: center;
}

/* Moodle Dialogue Settings (moodle-core-dialogue)  */
.moodle-dialogue-base .moodle-dialogue-lightbox {
    background-color: $gray-700;
}

// Prevent adding backdrops to popups in popups.
.pagelayout-popup {
    .moodle-dialogue-base {
        .moodle-dialogue-lightbox {
            background-color: transparent;
        }
        .moodle-dialogue {
            box-shadow: $popover-box-shadow;
        }
    }
}

.moodle-dialogue-base .hidden,
.moodle-dialogue-base .moodle-dialogue-hidden {
    display: none;
}

.no-scrolling {
    overflow: hidden;
}

.moodle-dialogue-base .moodle-dialogue-fullscreen {
    left: 0;
    top: 0;
    right: 0;
    bottom: -50px;
    position: fixed;
}

.moodle-dialogue-base .moodle-dialogue-fullscreen .moodle-dialogue-content {
    overflow: auto;
}

.moodle-dialogue-base .moodle-dialogue-wrap {
    background-color: $dialogue-base-bg;
    border: 1px solid $dialogue-base-border-color;
}

// Show is a bootstrap 2 class - but we use it for modals. We don't want to enable it everywhere because they removed
// it for a reason (conflicts with jquery .show()).
.modal.show {
    display: block;
}

.moodle-dialogue-base .moodle-dialogue-wrap .moodle-dialogue-hd {
    display: flex;
    padding: 1rem 1rem;
    border-bottom: 1px solid $dialogue-base-hd-border-color;
}

.moodle-dialogue-base .moodle-dialogue-wrap .moodle-dialogue-hd.yui3-widget-hd {
    // Undo some YUI damage.
    min-height: 3rem;
    color: initial;
    background: initial;
    font-size: 1.5rem;
    line-height: 1.5;
}

.moodle-dialogue-base .moodle-dialogue-wrap .moodle-dialogue-hd h5 {
    font-size: 1.5rem;
    font-weight: $font-weight-base;
    margin-bottom: 0;
    line-height: 1.5;
}

.moodle-dialogue-base .moodle-dialogue-wrap .moodle-dialogue-hd .yui3-widget-buttons {
    /*rtl:raw:
        left: 0;
        right: auto;
    */
    padding: 0;
    position: relative;
    margin-left: auto;
}

.moodle-dialogue-base .closebutton {
    padding: $modal-header-padding;
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
    position: relative;
    background-color: transparent;
    border: 0;
    background-image: none;
    box-shadow: none;
    opacity: 0.7;
    &:hover,
    &:active {
        opacity: 1;
    }
    &::after {
        content: "×";
    }
}

.moodle-dialogue-base .moodle-dialogue .moodle-dialogue-bd {
    padding: 0.5rem;
    body {
        background-color: $body-bg;
    }
}

.moodle-dialogue-base .moodle-dialogue-fullscreen .moodle-dialogue-content {
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0;
    border: 0;
}

.moodle-dialogue-exception .moodle-exception-param label {
    font-weight: bold;
}

.moodle-dialogue-exception .param-stacktrace label {
    background-color: $dialogue-exception-label-bg;
    border: 1px solid $dialogue-exception-label-border-color;
    border-bottom-width: 0;
}

.moodle-dialogue-exception .param-stacktrace pre {
    border: 1px solid $dialogue-exception-pre-border-color;
    background-color: $dialogue-exception-pre-bg;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-file {
    color: $dialogue-exception-file-color;
    font-size: $font-size-sm;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-line {
    color: map-get($theme-colors, 'warning');
    font-size: $font-size-sm;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-call {
    color: $dialogue-exception-call-color;
    font-size: 90%;
    border-bottom: 1px solid $dialogue-exception-call-border-color;
}

.moodle-dialogue-base .moodle-dialogue .moodle-dialogue-content .moodle-dialogue-ft:empty {
    display: none;
}

.moodle-dialogue-base .moodle-dialogue .moodle-dialogue-content .moodle-dialogue-ft.yui3-widget-ft {
    // Undo some YUI damage.
    background: initial;
}

.moodle-dialogue-confirm .confirmation-message {
    margin: 0.5rem 0;
}

.moodle-dialogue-confirm .confirmation-dialogue input {
    min-width: 80px;
}

.moodle-dialogue-exception .moodle-exception-message {
    margin: 1em;
}

.moodle-dialogue-exception .moodle-exception-param {
    margin-bottom: 0.5em;
}

.moodle-dialogue-exception .moodle-exception-param label {
    width: 150px;
}

.moodle-dialogue-exception .param-stacktrace label {
    display: block;
    margin: 0;
    padding: 4px 1em;
}

.moodle-dialogue-exception .param-stacktrace pre {
    display: block;
    height: 200px;
    overflow: auto;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-file {
    display: inline-block;
    margin: 4px 0;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-line {
    display: inline-block;
    width: 50px;
    margin: 4px 1em;
}

.moodle-dialogue-exception .param-stacktrace .stacktrace-call {
    padding-left: 25px;
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.moodle-dialogue .moodle-dialogue-bd .content-lightbox {
    opacity: 0.75;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $dialogue-lightbox-bg;
    text-align: center;
    padding: 10% 0;
}
/* Apply a default max-height on tooltip text */
.moodle-dialogue .tooltiptext {
    max-height: 300px;
}

.moodle-dialogue-base .moodle-dialogue.moodle-dialogue-tooltip {
    z-index: 3001;

    .moodle-dialogue-bd {
        overflow: auto;
    }
}

/**
 * Chooser Dialogues (moodle-core-chooserdialogue)
 *
 * This CSS belong to the chooser dialogue which should work both with, and
 * without javascript enabled
 */
/* Hide the dialog and it's title */
.chooserdialoguebody,
.choosertitle {
    display: none;
}

.moodle-dialogue.chooserdialogue .moodle-dialogue-content .moodle-dialogue-ft {
    margin: 0;
}

.chooserdialogue .moodle-dialogue-wrap .moodle-dialogue-bd {
    padding: 0;
    background: $chooserdialogue-bg;

    @include border-bottom-radius(10px);
}
/* Center the submit buttons within the area */
.choosercontainer #chooseform .submitbuttons {
    padding: 0.7em 0;
    text-align: right;
}
/* Fixed for safari browser on iPhone4S with ios7@mixin */

@media (max-height: 639px) {
    .ios .choosercontainer #chooseform .submitbuttons {
        padding: 45px 0;
    }
}

.choosercontainer #chooseform .submitbuttons input {
    min-width: 100px;
    margin: 0 0.5em;
}
/* Various settings for the options area */
.choosercontainer #chooseform .options {
    position: relative;
    border-bottom: 1px solid $chooserdialogue-options-border-color;
}
/* Only set these options if we're showing the js container */
.jschooser .choosercontainer #chooseform .alloptions {
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 240px;

    .option {
        input[type=radio] {
            display: inline-block;
        }

        .typename {
            display: inline-block;
            width: 55%;
        }
    }
}
/* Settings for option rows and option subtypes */
.choosercontainer #chooseform .moduletypetitle,
.choosercontainer #chooseform .option,
.choosercontainer #chooseform .nonoption {
    margin-bottom: 0;
    padding: 0 1.6em 0 1.6em;
}

.choosercontainer #chooseform .moduletypetitle {
    text-transform: uppercase;
    padding-top: 1.2em;
    padding-bottom: 0.4em;
    margin-bottom: 0.5rem;
    font-size: 100%;
}

.choosercontainer #chooseform .option .typename,
.choosercontainer #chooseform .nonoption .typename {
    padding: 0 0 0 0.5em;
}

.choosercontainer #chooseform .modicon + .typename {
    padding-left: 0;
}

.choosercontainer #chooseform .option input[type=radio],
.choosercontainer #chooseform .option span.typename {
    vertical-align: middle;
}

.choosercontainer #chooseform .option label {
    display: block;
    margin: 0;
    padding: ($spacer * 0.5) 0;
    border-bottom: 1px solid $choosercontainer-label-border-color;
}

.choosercontainer #chooseform .option .icon {
    margin: 0;
    padding: 0 $spacer;
}

.choosercontainer #chooseform .nonoption {
    padding-left: 2.7em;
    padding-top: 0.3em;
    padding-bottom: 0.1em;
}

.choosercontainer #chooseform .subtype {
    margin-bottom: 0;
    padding: 0 1.6em 0 3.2em;
}

.choosercontainer #chooseform .subtype .typename {
    margin: 0 0 0 0.2em;
}
/* The instruction/help area */
.jschooser .choosercontainer #chooseform .instruction,
.jschooser .choosercontainer #chooseform .typesummary {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 240px;
    margin: 0;
    padding: 1.6em;
    background-color: $choosercontainer-instruction-bg;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 2em;
}
/* Selected option settings */
.jschooser .choosercontainer #chooseform .instruction,
.choosercontainer #chooseform .selected .typesummary {
    display: block;
}

.choosercontainer #chooseform .selected {
    background-color: $choosercontainer-chooseform-selected-bg;
    margin-top: -1px;
    padding-top: 1px;
}


@include media-breakpoint-down(xs) {
    .jsenabled .choosercontainer #chooseform .alloptions {
        max-width: 100%;
    }

    .jsenabled .choosercontainer #chooseform .instruction,
    .jsenabled .choosercontainer #chooseform .typesummary {
        position: static;
    }
}

/**
 * Module chooser dialogue (moodle-core-chooserdialogue)
 *
 * This CSS belong to the chooser dialogue which should work both with, and
 * without javascript enabled
 */
.modchooser .modal-body {
    padding: 0;
    overflow-y: auto;
    min-height: 640px;
    display: flex;
    flex-direction: column;

    .searchresultitemscontainer-wrapper {
        min-height: 495px;
    }

    .carousel-item.active {
        display: flex;
    }
    .chooser-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    .loading-icon {
        opacity: 1;
        .icon {
            display: block;
            font-size: 3em;
            height: 1em;
            width: 1em;
        }
    }
    .carousel-item .loading-icon .icon {
        margin: 1em auto;
    }
    .searchbar {
        width: 100%;
    }
}

.modchooser .modal-footer {
    height: 70px;
    background: $modal-content-bg;
    .moodlenet-logo {
        .icon {
            height: 2.5rem;
            width: 6rem;
            margin-bottom: .6rem;
        }
    }
}

.modchoosercontainer.noscroll {
    overflow-y: hidden;
}

.modchoosercontainer .optionscontainer,
.modchoosercontainer .searchresultitemscontainer {
    overflow-x: hidden;
    .option {
        // 2 items per line.
        flex-basis: calc(50% - 0.5rem);
        .optionactions {
            .optionaction {
                cursor: pointer;
                color: $gray-600;
                i {
                    margin: 0;
                }
            }
        }
        .optioninfo {
            a {
                color: $gray-700;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.modchooser .modal-body .optionsummary {
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;
    height: 640px;

    .content {
        overflow-y: auto;
        .heading {
            .icon {
                height: 32px;
                width: 32px;
                font-size: 32px;
                padding: 0;
            }
        }
    }

    .actions {
        border-top: 1px solid $gray-300;
        background: $white;
    }
}

@include media-breakpoint-down(xs) {
    .path-course-view .modal-dialog.modal-lg,
    .path-course-view .modal-content,
    .modchooser .modal-body,
    .modchooser .modal-body .carousel,
    .modchooser .modal-body .carousel-inner,
    .modchooser .modal-body .carousel-item,
    .modchooser .modal-body .optionsummary,
    .modchoosercontainer,
    .optionscontainer,
    .searchresultitemscontainer {
        min-height: auto;
        height: 100%;
        overflow-y: auto;
    }
    .path-course-view .modal-dialog.modal-lg {
        margin: 0;
    }
    .modchooser .modal-body .searchresultitemscontainer-wrapper {
        min-height: auto;
    }
}

@include media-breakpoint-up(sm) {
    .modchoosercontainer .optionscontainer .option,
    .modchoosercontainer .searchresultitemscontainer .option {
        // Three items per line.
        flex-basis: calc(33.33% - 0.5rem);
    }
}

@include media-breakpoint-up(lg) {
    .modchoosercontainer .optionscontainer .option,
    .modchoosercontainer .searchresultitemscontainer .option {
        // Six items per line.
        flex-basis: calc(16.66% - 0.5rem);
    }
}

/* Form element: listing */
.formlistingradio {
    padding-bottom: 25px;
    padding-right: 10px;
}

.formlistinginputradio {
    float: left;
}

.formlistingmain {
    min-height: 225px;
}

.formlisting {
    position: relative;
    margin: 15px 0;
    padding: 1px 19px 14px;
    background-color: $formlisting-bg;
    border: 1px solid $formlisting-border-color;

    @include border-radius(4px);
}

.formlistingmore {
    position: absolute;
    cursor: pointer;
    bottom: -1px;
    right: -1px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    background-color: $formlistingmore-bg;
    border: 1px solid $formlistingmore-border-color;
    color: $formlistingmore-color;

    @include border-radius(4px 0 4px 0);
}

.formlistingall {
    margin: 15px 0;
    padding: 0;

    @include border-radius(4px);
}

.formlistingrow {
    cursor: pointer;
    border-bottom: 1px solid;
    border-color: $formlistingrow-border-color;
    border-left: 1px solid $formlistingrow-border-color;
    border-right: 1px solid $formlistingrow-border-color;
    background-color: $formlistingrow-bg;

    @include border-radius(0 0 4px 4px);
    padding: 6px;
    top: 50%;
    left: 50%;
    min-height: 34px;
    float: left;
    width: 150px;
}

body.jsenabled .formlistingradio {
    display: none;
}

body.jsenabled .formlisting {
    display: block;
}

a.criteria-action {
    padding: 0 3px;
    float: right;
}

div.criteria-description {
    padding: 10px 15px;
    margin: 5px 0;
    background: none repeat scroll 0 0 $criteria-desc-bg;
    border: 1px solid $criteria-desc-border-color;
}

ul.badges {
    margin: 0;
    list-style: none;
}

.badges li {
    position: relative;
    display: inline-block;
    padding-top: 1em;
    text-align: center;
    vertical-align: top;
    width: 150px;
}

.badges li .badge-name {
    display: block;
    padding: 5px;
}

.badges li > img {
    position: absolute;
}

.badges li .badge-image {
    width: 100px;
    height: 100px;
    left: 10px;
    top: 0;
    z-index: 1;
}

.badges li .badge-actions {
    position: relative;
}

.badges li .expireimage {
    //background-image: url([[pix:i/expired]]);
    background-repeat: no-repeat;
    background-size: 100px 100px;
    width: 100px;
    height: 100px;
    left: 25px;
    top: 15px;
    position: absolute;
    z-index: 10;
    opacity: 0.85;
}

#badge-image {
    background-color: transparent;
    padding: 0;
    position: relative;
    min-width: 100px;
    width: 20%;
    display: inline-block;
    vertical-align: top;
    margin-top: 17px;
    margin-bottom: 20px;

    .expireimage {
        //background-image: url([[pix:i/expired]]);
        background-repeat: no-repeat;
        background-size: 100px 100px;
        width: 100px;
        height: 100px;
        left: 0;
        top: 0;
        opacity: 0.85;
        position: absolute;
        z-index: 10;
    }

    .singlebutton {
        padding-top: 5px;
        display: block;

        button {
            margin-left: 4px;
        }
    }
}

#badge-details {
    display: inline-block;
    width: 79%;
}

#badge-overview dl,
#badge-details dl {
    margin: 0;

    dt,
    dd {
        vertical-align: top;
        padding: 3px 0;
    }

    dt {
        clear: both;
        display: inline-block;
        width: 20%;
        min-width: 100px;
    }

    dd {
        display: inline-block;
        width: 79%;
        margin-left: 1%;
    }
}

#badge-criteria li li {
    list-style-type: none;
}

#badge-image-col {
    flex: 0 0 400px;
}

.badge-profile {
    vertical-align: top;
}

.connected {
    color: map-get($theme-colors, 'success');
}

.notconnected {
    color: map-get($theme-colors, 'danger');
}

.connecting {
    color: map-get($theme-colors, 'warning');
}

#page-badges-award .recipienttable tr td {
    vertical-align: top;
}

#page-badges-award .recipienttable tr td.actions .actionbutton {
    margin: 0.3em 0;
    padding: 0.5em 0;
    width: 100%;
}

#page-badges-award .recipienttable tr td.existing,
#page-badges-award .recipienttable tr td.potential {
    width: 42%;
}

#issued-badge-table .activatebadge {
    display: inline-block;
}

.statusbox.active {
    background-color: $state-success-bg;
}

.statusbox.inactive {
    background-color: $state-warning-bg;
}

.statusbox {
    text-align: center;
    margin-bottom: 5px;
    padding: 5px;
}

.statusbox .activatebadge {
    display: inline-block;
}

.statusbox .activatebadge input[type=submit] {
    margin: 3px;
}

.activatebadge {
    margin: 0;
    text-align: left;
    vertical-align: middle;
}

img#persona_signin {
    cursor: pointer;
}

.addcourse {
    float: right;
}

.invisiblefieldset {
    display: inline;
    padding: 0;
    border-width: 0;
}

/** Page header */
#page-header {
    h1.h2 {
        font-weight: bold;
    }
    .logo {
        margin: $spacer 0;
        img {
            max-height: 75px;
        }
    }
}

/** Navbar logo. */
nav.navbar .logo img {
    max-height: 35px;
}

.nav.usernav {
    .nav-item {
        display: flex;
    }
    .usermenu .dropdown-toggle {
        padding: 0 0.5rem;
    }
}

/** Header-bar styles **/
.page-context-header {
    // We need to be explicit about the height of the header.
    $pageHeaderHeight: 140px;

    // Do not remove these rules.
    overflow: hidden;
    padding: 0.25rem 0;

    display: flex;

    .page-header-image {
        & > a {
            display: inline-block;
        }
    }

    .page-header-headings,
    .header-button-group {
        position: relative;
        line-height: 24px;
        vertical-align: middle;
    }

    .header-button-group {
        display: block;
        float: left;
    }
}

ul.dragdrop-keyboard-drag li {
    list-style-type: none;
}

a.disabled:hover,
a.disabled {
    text-decoration: none;
    cursor: default;
    font-style: italic;
    color: $text-muted;
}

body.lockscroll {
    height: 100%;
    overflow: hidden;
}

.progressbar_container {
    max-width: 500px;
    margin: 0 auto;
}

/* IE10 only fix for calendar titling */
.ie10 .yui3-calendar-header-label {
    display: inline-block;
}

dd:before,
dd:after {
    display: block;
    content: " ";
}

dd:after {
    clear: both;
}

// Active tabs with links should have a different
// cursor to indicate they are clickable.
.nav-tabs > .active > a[href],
.nav-tabs > .active > a[href]:hover,
.nav-tabs > .active > a[href]:focus {
    cursor: pointer;
}

.inplaceeditable {
    &.inplaceeditingon {
        position: relative;

        .editinstructions {
            margin-top: -30px;
            font-weight: normal;
            margin-right: 0;
            margin-left: 0;
            left: 0;
            right: auto;
            white-space: nowrap;
        }
        @include media-breakpoint-up(sm) {
            input {
                width: 330px;
                vertical-align: text-bottom;
                margin-bottom: 0;
                &[role="combobox"] {
                    width: auto;
                }
            }
        }

        select {
            margin-bottom: 0;
        }
    }

    .quickediticon img {
        opacity: 0.2;
    }

    .quickeditlink {
        color: inherit;
        text-decoration: inherit;
    }

    &:hover .quickeditlink .quickediticon img,
    .quickeditlink:focus .quickediticon img {
        opacity: 1;
    }

    &.inplaceeditable-toggle .quickediticon {
        display: none;
    }

    &.inplaceeditable-autocomplete {
        display: block;
    }
}

h3.sectionname .inplaceeditable.inplaceeditingon .editinstructions {
    margin-top: -20px;
}

/** Chart area. */
.chart-area {
    @include media-breakpoint-up(lg) {
        .chart-image {
            position: relative;
            margin: auto;
            height: 48vh;
            width: 46vw;
        }
    }

    .chart-table-data {
        display: none;
    }

    .chart-table {
        .chart-output-htmltable caption {
            white-space: nowrap;
        }
        /** When accessible, we display the table only. */
        &.accesshide {
            .chart-table-expand {
                display: none;
            }
            .chart-table-data {
                display: block;
            }
        }
    }
}

/* YUI 2 Tree View */
/*rtl:raw:
.ygtvtn,
.ygtvtm,
.ygtvtmh,
.ygtvtmhh,
.ygtvtp,
.ygtvtph,
.ygtvtphh,
.ygtvln,
.ygtvlm,
.ygtvlmh,
.ygtvlmhh,
.ygtvlp,
.ygtvlph,
.ygtvlphh,
.ygtvdepthcell,
.ygtvok,
.ygtvok:hover,
.ygtvcancel,
.ygtvcancel:hover {
    //background-image: url([[pix:theme|yui2-treeview-sprite-rtl]]);
}
*/

.hover-tooltip-container {
    position: relative;

    .hover-tooltip {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        /*rtl:ignore*/
        left: 50%;
        top: calc(-50% - 5px);
        transform: translate(-50%, -50%);
        background-color: $hover-tooltip-bg;
        border: 1px solid $hover-tooltip-border-color;
        border-radius: .3rem;
        box-sizing: border-box;
        padding: 5px;
        white-space: nowrap;
        transition: opacity 0.15s, visibility 0.15s;
        z-index: 1000;

        &:before {
            content: '';
            display: inline-block;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid $hover-tooltip-border-color;
            position: absolute;
            bottom: -8px;
            left: calc(50% - 8px);
        }

        &:after {
            content: '';
            display: inline-block;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid $hover-tooltip-border-top-color;
            position: absolute;
            bottom: -6px;
            left: calc(50% - 7px);
            z-index: 2;
        }
    }

    &:hover {
        .hover-tooltip {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.15s 0.5s, visibility 0.15s 0.5s;
        }
    }
}

#region-flat-nav {
    padding-right: 0;
    padding-left: 0;
    .nav {
        margin-right: $grid-gutter-width * 0.5;
        background-color: $card-bg;
    }
    @include media-breakpoint-down(sm) {
        .nav {
            margin-top: $grid-gutter-width;
            margin-right: 0;
        }
    }
}

// Footer link colour was added to allow the colour of footer links to be changed,
// but really in bootstrap we want the colour of links on .bg-inverse to be changed
// rather than being specific to the footer. This is kept for backwards compatibility.
$footer-link-color: $bg-inverse-link-color !default;

.footer-dark {
    a {
        color: $footer-link-color;
        text-decoration: underline;

        .icon {
            color: $footer-link-color;
        }

        &:focus .icon {
            color: $body-color;
        }
    }
}

.btn-footer-popover {
    display: none;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    @include box-shadow($popover-box-shadow);
}

.btn-footer-communication {
    display: none;
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    @include box-shadow($popover-box-shadow);
}

.hasstickyfooter .btn-footer-popover {
    bottom: calc(1rem + #{$stickyfooter-height});
}

.hasstickyfooter .btn-footer-communication {
    bottom: calc(4rem + #{$stickyfooter-height});
}

.popover.footer {
    .popover-body {
        padding: 0;
        .footer-section {
            a {
                color: $body-color;
                text-decoration: underline;
                .icon {
                    color: $body-color;
                }
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.footer-support-link {
    padding-bottom: 5px;
}

@include media-breakpoint-up(sm) {
    .jsenabled #page-footer .footer-content-popover {
        display: none;
    }
    .jsenabled .btn-footer-popover,
    .jsenabled .btn-footer-communication {
        display: block;
        z-index: $zindex-dropdown;
    }
}

.bg-inverse a {
    color: $bg-inverse-link-color;
    text-decoration: underline;
    .icon {
        color: $bg-inverse-link-color;
    }
}

.sitelink {
    img {
        width: 112px;
    }
}

.competency-tree {
    ul {
        padding-left: 1.5rem;
    }
}

.sr-only-focusable {
    &:active,
    &:focus {
        z-index: 1031;
        position: fixed;
        background: $sr-only-active-bg;
        padding: 7px;
        left: 0;
        top: 0;
    }
}

[data-drag-type="move"] {
    cursor: move;
    touch-action: none;
}

.clickable {
    cursor: pointer;
}

.overlay-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-icon-bg;

    .loading-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .icon {
            height: 30px;
            width: 30px;
            font-size: 30px;
        }
    }
}

.w-auto {
    width: auto;
}

.bg-pulse-grey {
    animation: bg-pulse-grey 2s infinite linear;
}

@keyframes bg-pulse-grey {
    0% {
        background-color: $gray-100;
    }
    50% {
        background-color: darken($gray-100, 5%);
    }
    100% {
        background-color: $gray-100;
    }
}

@each $size, $length in $spacers {
    .line-height-#{$size} {
        line-height: $length !important; /* stylelint-disable-line declaration-no-important */
    }
}

.dir-rtl {
    .dir-rtl-hide {
        display: none;
    }
}

.dir-ltr {
    .dir-ltr-hide {
        display: none;
    }
}

.paged-content-page-container {
    min-height: 3.125rem;
}

body.h5p-embed {
    #page-content {
        display: inherit;
    }
    #maincontent {
        display: none;
    }
    .h5pmessages {
        min-height: 230px; // This should be the same height as default core_h5p iframes.
    }
}

#h5pcontenttypes td {
    vertical-align: middle;
}

#page.drawers {
    form#h5peditor,
    form#coolh5peditor,
    .core_contentbank_viewcontent {
        max-width: $h5p-content-maxwidth;
        margin: 0 auto;
    }
}

.text-decoration-none {
    text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
}

.colour-inherit {
    color: inherit !important; /* stylelint-disable-line declaration-no-important */
}

.position-right {
    right: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.overflow-hidden {
    overflow: hidden !important; /* stylelint-disable-line declaration-no-important */
}

.text-break {
    overflow-wrap: break-word !important; /* stylelint-disable-line declaration-no-important */
}

.word-break {
    word-break: break-word !important; /* stylelint-disable-line declaration-no-important */
}

.z-index-0 {
    z-index: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.z-index-1 {
    z-index: 1 !important; /* stylelint-disable-line declaration-no-important */
}

// These floats are deprecated in Bootstrap 4.3.1. It is still okay to use them in Moodle.
.float-left {
    float: left !important; /* stylelint-disable-line declaration-no-important */
}

.float-right {
    float: right !important; /* stylelint-disable-line declaration-no-important */
}

.img-responsive {
    @include img-fluid();
}

input[disabled] {
    cursor: not-allowed;
}

.custom-select {
    width: auto;
    max-width: 100%;
}

.fade.in {
    opacity: 1;
}

.clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.word-break-all {
    word-break: break-all;
}

.matchtext {
    background-color: lighten($primary, 40%);
    color: $body-color;
    height: 1.5rem;
}

.border-radius {
    @if $enable-rounded {
        @include border-radius($card-border-radius);
    }
}

.v-hidden {
    visibility: hidden;
}

// Any dialog (modal or dropdown) forced max-widths limits.
.dialog-big {
    max-width: $modal-md;
}

.dialog-small {
    max-width: $modal-sm;
}

@include media-breakpoint-up(sm) {
    .dialog-big {
        width: $modal-md;
    }

    .dialog-small {
        width: $modal-sm;
    }
}

/*
 * Helpers to show elements only when a parent element has focus or hover.
 */

// Add this class to the element to hide.
.v-parent-focus {
    opacity: 0;
    visibility: hidden;
}

// Add this class to the parent element to control focus.
.focus-control:focus-within .v-parent-focus,
.focus-control:hover .v-parent-focus {
    opacity: 1;
    visibility: visible;
}

// Emoji picker.
$picker-width: 350px !default;
$picker-width-xs: 320px !default;
$picker-height: 400px !default;
$picker-row-height: 40px !default;
$picker-emoji-button-size: 40px !default;
$picker-emoji-button-font-size: 24px !default;
$picker-emoji-category-count: 9 !default;
$picker-emojis-per-row: 7 !default;

.emoji-picker {
    width: $picker-width;
    height: $picker-height;

    .category-button {
        padding: .375rem 0;
        height: 100%;
        width: divide($picker-width, $picker-emoji-category-count);
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid transparent;

        &.selected {
            border-bottom: 2px solid map-get($theme-colors, 'primary');
        }
    }

    .emojis-container,
    .search-results-container {
        min-width: $picker-emojis-per-row * $picker-emoji-button-size;
    }

    .picker-row {
        height: $picker-row-height;

        .category-name {
            line-height: $picker-row-height;
        }

        .emoji-button {
            height: $picker-emoji-button-size;
            width: $picker-emoji-button-size;
            line-height: $picker-emoji-button-size;
            font-size: $picker-emoji-button-font-size;
            overflow: hidden;

            @include hover-focus {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .emoji-preview {
        height: $picker-row-height;
        font-size: $picker-row-height;
        line-height: $picker-row-height;
    }

    .emoji-short-name {
        line-height: $picker-row-height * 0.5;
    }

    @include media-breakpoint-down(xs) {
        width: $picker-width-xs;
    }
}

.emoji-auto-complete {
    height: $picker-row-height;

    .btn.btn-link.btn-icon.emoji-button {
        height: $picker-emoji-button-size;
        width: $picker-emoji-button-size;
        line-height: $picker-emoji-button-size;
        font-size: $picker-emoji-button-font-size;

        &.active {
            background-color: $gray-200;
        }
    }
}

.toast-wrapper {
    max-width: $toast-max-width;
    max-height: 0;
    // Place these above any modals and other elements.
    z-index: 1051;

    > :first-child {
        margin-top: $spacer;
    }
}

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        a {
            color: darken(theme-color-level($color, $alert-color-level), 10%);
        }
        // Darken the close button text colour inside notification alerts for better contrast.
        .close {
            color: darken(theme-color-level($color, $alert-color-level), 20%);
            opacity: 0.6;
        }
    }
}
.alert a {
    font-weight: $font-weight-bold;
}

.breadcrumb:empty {
    padding: 0;
}

@include media-breakpoint-down(sm) {
    #page-navbar {
        width: 100%;
    }

    .breadcrumb:not(:empty) {
        width: 100%;
        flex-wrap: nowrap;
        margin-bottom: $spacer * 0.5;
        .breadcrumb-item {
            padding-top: $spacer * 0.33333;
            padding-bottom: $spacer * 0.33333;
            display: inline-flex;
            overflow: hidden;
        }
        .breadcrumb-item a,
        .breadcrumb-item span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .mform {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    .pagination {
        flex-wrap: wrap;
        justify-content: center;
    }
    .custom-select {
        max-width: 100%;
    }
    .card .card-body {
        padding: $card-spacer-x * 0.5;
    }
    #page-header {
        .card {
            border: 0;
            .card-body {
                padding: 0;
            }
        }
    }
    .nav-tabs:not(.more-nav),
    .nav-pills {
        margin: 0;
        border: 0;
        padding: $spacer * 0.125;
        background-color: $gray-200;
        .nav-item {
            flex: 1 1 auto;
            text-align: center;
        }
        .nav-link {
            background: $white;
            border: 0;
            margin: $spacer * 0.125;
            &.active {
                @include button-outline-variant($gray-600);
                border-color: $gray-600;
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, "sm")) and (max-height: 320px) {
    div#page {
        margin-top: 0;
    }
    .navbar.fixed-top {
        position: relative;
        z-index: inherit;
    }
}

.link-underline {
    text-decoration: underline;
    &:focus {
        text-decoration: none;
    }
}

.alert.cta {
    .cta-icon {
        .icon {
            padding: 0.3rem;
            &.fa {
                border-radius: 50%;
                border-style: solid;
                border-width: 0.125rem;
            }
        }
    }
}
.core_payment_gateways_modal .custom-control-label::before,
.core_payment_gateways_modal .custom-control-label::after {
    top: 45%;
}

$scrollbar-thumb: $primary;
$scrollbar-track: lighten($primary, 40%);
// simple scrollbars
.visual-scroll-x {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb $scrollbar-track;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        height: 8px; // for horizontal scrollbars
        -webkit-appearance: none;
        appearance: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
        border-right: $border-width solid $white;
    }
    &::-webkit-scrollbar-track {
        background-color: $scrollbar-track;
        border-right: $border-width solid $white;
    }
}

// Thin scrollbars.
@mixin thin-scrolls($bg-track) {

    // Firefox.
    scrollbar-width: thin;
    scrollbar-color: $thin-scroll-bg-thumb $bg-track;

    // Chrome, Edge and Safari.
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: $bg-track;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thin-scroll-bg-thumb;
        border-radius: 20px;
        border: 3px solid $bg-track;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: $thin-scroll-bg-hover;
    }
}

// Add top/bottom shadow to scrolling area.
@mixin scroll-shadow($background-colour: $white, $shadow-colour: $black, $shadow-size: 1rem) {
    background:
        linear-gradient($background-colour 30%, rgba($background-colour, 0)) center top,
        linear-gradient(rgba($background-colour, 0), $background-colour 70%) center bottom,
        radial-gradient(farthest-side at 50% 0, rgba($shadow-colour, 0.2), rgba($shadow-colour, 0)) center top,
        radial-gradient(farthest-side at 50% 100%, rgba($shadow-colour, 0.2), rgba($shadow-colour, 0)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% $shadow-size, 100% $shadow-size, 100% calc(#{$shadow-size} / 2), 100% calc(#{$shadow-size} / 2);
    background-attachment: local, local, scroll, scroll;
}

// Generic dropzones and dragging styles.

body.dragging {

    .drop-zone {
        border: 1px dashed $dropzone-border;
    }

    .drop-up {
        border-top: 1px solid $dropzone-border;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .drop-down {
        border-bottom: 1px solid $dropzone-border;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .dragging {
        opacity: .6;
    }
}

.dragicon {
    visibility: hidden;
}

.draggable:hover .dragicon {
    visibility: visible;
    cursor: move;
}

// Generic classes reactive components can use.

.overlay-preview {
    background-color: $overlay-preview-bg;
    border: 2px dashed $primary;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .overlay-preview-wrapper {
        position: absolute;
        top: 0;
        padding: 2rem;
        width: 100%;
    }

    .overlay-preview-content {
        position: relative;
        top: 0;
        padding: $modal-inner-padding;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        background-color: $primary;
        color: $white;
        text-align: center;
        font-size: $font-size-lg;
        @include border-radius();
    }
}

.overlay-preview-borders {
    outline: 2px dashed $primary;
}

.waitstate {
    display: none;
}

.stateready {
    .waitstate {
        display: inherit;
    }
    .whilenostate {
        display: none;
    }
}

// Collapsible list.

.collapse-list {
    .collapse-list-item {
        padding: $collapse-list-item-padding-y $collapse-list-item-padding-x;
        @include hover-focus() {
            background-color: $collapse-list-item-hover-bg;
            border-color: $collapse-list-item-hover-border;
        }
    }
    .collapse-list-item-content {
        .collapse-list-item {
            padding-left: calc(#{$collapse-list-item-padding-x} * 3);
        }
    }
}

.drawers {
    .block_myoverview {
        border: 0;
        & > .card-body {
            padding-top: 0 !important; /* stylelint-disable-line declaration-no-important */
            padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
            padding-right: 0 !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

.dropdown-toggle::after {
    @extend .fa-solid;
    content: fa-content($fa-var-chevron-down);
    margin-right: 0;
    margin-left: 4px;
    font-size: 9px;
    width: 9px;
    border: 0;

}

.dropleft .dropdown-toggle::before {
    border: 0;
    @extend .fa-solid;
    content: fa-content($fa-var-chevron-left);
    font-size: 9px;
    margin-left: 0;
    margin-right: 4px;
    width: 9px;
}

.dir-rtl .dropleft .dropdown-toggle::before {
    content: fa-content($fa-var-chevron-right);
}

.dropright .dropdown-toggle::after {
    border: 0;
    @extend .fa-solid;
    content: fa-content($fa-var-chevron-right);
}

.dir-rtl .dropright .dropdown-toggle::after {
    content: fa-content($fa-var-chevron-left);
}


.dropup .dropdown-toggle::after {
    border: 0;
    @extend .fa-solid;
    content: fa-content($fa-var-chevron-up);
}

.select-menu {
    li:first-child {
        ul[role="group"] {
            padding: 0;
        }
    }

    ul[role="group"] {
        padding: 0.3rem 0 0 0;
        margin: 0;
        li:first-child {
            cursor: default;
            color: $gray-600;
            padding: 0.25rem 1.5rem;
            display: block;
        }
        .dropdown-item {
            padding-left: 2.5rem;
        }
    }
    .dropdown-item[aria-selected="true"] {
        font-weight: bold;
    }
}

[role="listbox"] [role="option"] {
    cursor: pointer;

    &[aria-selected="true"] {
        font-weight: bold;
    }
}

.initialbargroups ul {
    -webkit-margin-start: 0; /* stylelint-disable-line */
    margin-right: -1px;
}

.page-item {
    &:first-child {
        .page-link {
            .initialbargroups & {
                @include border-left-radius(0);
                @include border-right-radius(0);
            }
            .initialbargroups .pagination-lg:first-child & {
                @include border-left-radius($pagination-border-radius-lg);
            }
            .initialbargroups .pagination-sm:first-child & {
                @include border-left-radius($pagination-border-radius-sm);
            }
        }
    }
    &:last-child {
        .page-link {
            .initialbargroups & {
                @include border-left-radius(0);
                @include border-right-radius(0);
            }
            .initialbargroups .pagination-lg:last-child & {
                @include border-right-radius($pagination-border-radius-lg);
            }
            .initialbargroups .pagination-sm:last-child & {
                @include border-right-radius($pagination-border-radius-sm);
            }
        }
    }
}

blockquote {
    margin: 0 0.5rem 1rem;
    padding-left: 1rem;
    color: $gray-700;
    border-left: 5px solid $gray-400;
}

/* Prevent long strings exceeding page width */
.page-header-headings,
.coursename,
.categoryname,
.breadcrumb-item {

    &:not(.text-truncate) {
        word-break: normal;
        overflow-wrap: anywhere;
        white-space: normal;
    }
}

/* Showmore component */
.showmore-container {
    &.collapsed {
        .collapsed-content {
            display: block;
        }
        .expanded-content {
            display: none;
        }
    }
    &:not(.collapsed) {
        .collapsed-content {
            display: none;
        }
        .expanded-content {
            display: block;
        }
    }
    button {
        float: right;
        &.btn-link {
            text-decoration: none;
        }
        .icon {
            font-size: $btn-font-size-sm;
            margin: 0;
        }
    }
}

/* Combobox search dropdowns */
.usersearchdropdown,
.gradesearchdropdown,
.groupsearchdropdown {
    max-width: 350px;
    .searchresultitemscontainer {
        max-height: 170px;
        overflow: auto;
        /* stylelint-disable declaration-no-important */
        img {
            height: 48px !important;
            width: 48px !important;
        }
    }
}

/* Bulk actions in sticky footer. */
#sticky-footer {
    [data-type="bulkactions"] {
        display: flex;
        flex: 0 0 100%;
        align-items: center;
    }
}

/* Choice list component. */
.choicelist {
    // Choicelist is designed to fit inside a small modal.
    min-width: calc(#{$modal-sm} - 25px);

    // Align the font based icons.
    i.icon {
        vertical-align: middle;
    }
}

&.path-my.pagelayout-mydashboard {
  #page {
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }

  #page-header {
    display: none;
  }

  #page.drawers {
    padding-left: 0;
    padding-right: 0;
  }

  #page.drawers .main-content .main-inner {
    background-color: transparent;
  }

  #page.drawers .main-content .main-inner div[role='main'] {
    padding-left: 0;
    padding-right: 0;
  }

  .main-content {
    padding: 10px 0 0;
    position: relative;
    overflow: auto;
    //background: no-repeat center/cover;
    //background: linear-gradient(-180deg, #101213 40%, #1f2024 60%);
    background: $gray-900;

    //overlay
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $brand-dashboard-content-overlay;
      pointer-events: none;
      opacity: 0;
    }
  }

  .main-content {
    .block-region {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    .block {
      flex-basis: 100%;
    }

    .block_news_items,
    .block_badges {
      flex-basis: calc(50% - 10px);
    }
  }
}

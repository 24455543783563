// Chat (more!)
// -------------------------
.yui-skin-sam .yui-layout.path-mod-chat-gui_ajax {
    background-color: $body-bg;

    .yui-layout-unit div.yui-layout-bd-nohd,
    .yui-layout-unit div.yui-layout-bd-noft,
    .yui-layout-unit div.yui-layout-bd,
    .yui-layout-unit-right,
    .yui-layout-unit-bottom {
        border: 0;
    }

    .yui-layout-unit-right,
    .yui-layout-unit-bottom {
        border-radius: 0;
    }

    .yui-layout-unit div.yui-layout-bd {
        background-color: transparent;
    }
    .yui-layout-unit.yui-layout-unit-center div.yui-layout-bd {
        background-color: $gray-100;
    }
    // Override the default padding added by function $OUTPUT->box();
    #chat-input-area.py-3 {
        padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
    #chat-input-area table.generaltable,
    #chat-input-area table.generaltable td.cell {
        border: 0;
        padding: 3px 15px;
        white-space: nowrap;
        margin-bottom: 0;
    }

    #chat-userlist {
        padding: 10px 5px;

        #users-list {
            border-top: 1px solid $gray-300;
            border-bottom: 1px solid $body-bg;

            li {
                border-top: 1px solid $body-bg;
                border-bottom: 1px solid $gray-300;
                padding: 5px 10px;
            }

            img {
                margin-right: 8px;
                border: 1px solid $chat-users-list-img-border-color;
                border-radius: 4px;
                max-width: none;
            }
        }
    }

    #chat-messages {
        margin: 20px 25px;

        .chat-event.course-theme {
            text-align: center;
            margin: 10px 0;
            font-size: $font-size-sm;
            color: $gray-700;
        }

        .chat-message.course-theme {
            margin-bottom: $card-spacer-y;
            @include border-radius($card-border-radius);
            border: $card-border-width solid $card-border-color;
            padding: $card-spacer-x;

            .time {
                float: right;
                font-size: 11px;
                color: $gray-700;
            }
        }

        .chat-message.course-theme {
            background-color: $white;

            .user {
                font-weight: bold;
            }
        }
    }
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active,
select:autofill {
  -webkit-text-fill-color: $brand-content-text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba($white, 0.1);
}

.form-control:focus {
  box-shadow: 0 0 0 2px $input-btn-focus-color;
}

input[type="file"] {
  @include fun-button-secondary;

  font-family: $font-family-sans-serif;
  font-weight: $font-weight-normal;
  text-transform: none;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

//Label typography
label,
#fitem_id_currentpicture > div > span,
#id_imagefile_label {
  font-weight: $font-weight-semibold;
}

//Select
.custom-select {
  //right icon
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='white' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    right 0.75rem center / 8px 10px no-repeat;
  background-color: $custom-select-bg;
}

//Form Action Buttons Arrangement
fieldset > div:has(.fitem) {
  column-gap: 10px;
}
fieldset > div:has(.fitem.btn-cancel) {
  .fitem:not(.btn-cancel) {
    order: 1;
  }
}
.buttons:has(> .singlebutton) {
  display: flex;
  column-gap: 10px;
}

//Form Action Buttons Horizontal Spacing (only for buttons side-by-side markup)
.btn + .btn {
  margin: 0 10px;
}

//Action buttons spacing
#fgroup_id_buttonar {
  margin-top: 20px;

  .felement fieldset {
    @include media-breakpoint-down(sm) {
      padding: 0 10px !important;
    }
  }
}

//Form group dividers
fieldset {
  border-color: $brand-divider !important;
}

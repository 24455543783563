/*
  Mutiple choice with images as choices
*/
.answer:has(p img:only-child) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;

  div[class*="r"] {
    margin: 0 !important;

    .answernumber + div {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        aspect-ratio: 1;
        background-color: $primary;
        border-radius: 50%;
        opacity: 1;
        transform: translate(-50%, -50%) scale(0) rotate(180deg);
      }
    }

    .answernumber:before,
    .answernumber + div:before {
      @include media-breakpoint-down(sm) {
        aspect-ratio: unset;
        width: 150%;
        height: 150%;
      }
    }

    .answernumber {
      //Selected animating background
      &:before {
        transform: translate(-50%, -50%) scale(0) rotate(180deg);
      }
    }
  }

  //Selected State
  input[type="radio"]:checked {
    ~ div[data-region="answer-label"] {
      .answernumber {
        //Selected animating background
        &:before {
          transform: translate(-50%, -50%) scale(1.1);
          transition: transform 0.5s $easeOutExpo,
            border-radius 0.5s $easeOutExpo;
        }
      }

      .answernumber + div {
        &:before {
          opacity: 0;
          transition: transform 0.5s $easeOutExpo,
            border-radius 0.5s $easeOutExpo, opacity 0.6s linear;
          border-radius: 0;
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }
  }

  //Correct
  div[class*="r"].correct div[data-region="answer-label"] {
    background-color: transparent;
    border-color: $brand-correct;
  }

  //Wrong
  div[class*="r"].incorrect div[data-region="answer-label"] {
    background-color: transparent;
    border-color: $brand-wrong;
  }

  //x or check mark
  div[class*="r"].correct,
  div[class*="r"].incorrect {
    div[data-region="answer-label"] {
      .answernumber + div p:before {
        position: absolute;
        bottom: 7px;
        left: 19px;
        font-size: 30px;

        @include media-breakpoint-down(sm) {
          font-size: 15px;
          bottom: 10px;
          left: 14px;
        }
      }
    }
  }

  img {
    object-fit: contain;
    width: 100%;
    max-height: 300px;
  }
}

.signupform {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.signupform__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.signupform__heading {
  @include heading-display-medium;

  font-family: $headings-font-family;
  text-transform: uppercase;
  color: $primary;
  text-align: center;
  font-size: 3.75rem;

  @include media-breakpoint-down(sm) {
    font-size: 40px;
  }

  span {
    display: block;
  }

  span:nth-child(1) {
    font-size: 20px;
    color: $body-color;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  span:nth-child(3) {
    color: $black;
    margin-top: 5px;
  }
}

.signupform__logo-image {
  font-size: 0;

  img {
    object-fit: contain;
    width: 300px;
    height: auto;
    transform: translateX(7px);

    @include media-breakpoint-down(sm) {
      width: 200px;
    }
  }
}

.signupform__meta {
  font-weight: bold;
  color: $brand-text-black;
  text-transform: uppercase;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.signupform__region {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.signupform__region i {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1;
  transform: translateY(-1px);
}

.signupform .mform select.custom-select {
  width: 100%;
}

.signupform .form-control-feedback {
  text-align: left;
}

//Form Layout
.signupform .mform {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  @include media-breakpoint-down(sm) {
    display: block !important;
    margin: 0;
  }
}

.signupform .mform > * {
  display: block;
  flex: 0 0 50%;
  padding: 5px;
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.signupform .mform #fitem_id_passwordpolicyinfo {
  order: -1;
  flex: 0 0 100%;

  @include media-breakpoint-down(sm) {
    margin: 10px 0;
  }
}

.signupform .mform #fgroup_id_buttonar {
  flex: 0 0 100%;
}
.signupform .mform #fgroup_id_buttonar fieldset > * {
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    row-gap: 10px;
  }
}

.signupform .mform #fgroup_id_buttonar fieldset .form-group {
  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }
}
.signupform .mform #fgroup_id_buttonar fieldset .form-group > span,
.signupform .mform #fgroup_id_buttonar fieldset .form-group input {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.signupform .fdescription.required {
  display: none;
}

//Custom fields
.signupform .mform > fieldset[id*='id_category']:first-of-type {
  flex: 0 0 100%;
  border-top: 1px solid $brand-divider;
  margin-top: 10px;
  padding-top: 15px;
}
.signupform .mform > fieldset[id*='id_category'] {
  border-bottom: 0;
}
.signupform .mform > fieldset[id*='id_category'] h3 {
  font-size: 18px;
}
.signupform .mform > fieldset[id*='id_category'] .fcontainer {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  gap: 0;

  @include media-breakpoint-down(sm) {
    display: block !important;
    margin: 0;
  }
}
.signupform .mform > fieldset[id*='id_category'] .fcontainer .form-group {
  display: block;
  flex: 0 0 50%;
  padding: 5px;
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}
.signupform .mform a[data-toggle='collapse'] {
  display: none;
}

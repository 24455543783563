.que.multichoice {
  fieldset {
    overflow: visible;
  }
}

/*
  Multiple choice
*/
.answer div[class*='r']:has(input[type='radio']:checked) {
  z-index: 10;
}
.answer div[class*='r'] {
  position: relative;
  margin: 5px 0 !important;
  padding: 0 !important;
  border: 1px solid rgba($black, 0.5);
  border-radius: $brand-border-radius;

  //Unselected State
  input[type='radio'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;

    ~ div[data-region='answer-label'] {
      width: 100% !important;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 0;
      padding: 0;
      border-radius: 4px;
      position: relative;

      p {
        @include media-breakpoint-down(sm) {
          font-size: 15px;
        }
      }

      //Selected border
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: transparent;
        z-index: 0;
        pointer-events: none;
        opacity: 0;
        transform: translate(-50%, -50%) scaleX(0);
      }
    }
  }

  //Border cover
  .answernumber {
    font-size: 0;
    display: block;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: rgba($black, 0.1);
    z-index: 1;
    pointer-events: none;
    border-radius: 0;
    overflow: hidden;

    //Selected animating background
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      aspect-ratio: 1;
      background-color: $primary;
      border-radius: 50%;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    //Selected inner glow
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      //box-shadow: inset 0 0 20px 2px #ff0;
      box-shadow: inset 0 0 20px 2px red;
      opacity: 0;
    }
  }

  .answernumber + div {
    position: relative;
    padding: 10px;
    margin: 0 !important;
    z-index: 2;
    overflow: hidden;

    p {
      font-size: 30px;
      font-weight: $font-weight-bold;
      padding: 0;
      padding-bottom: 4px;
      margin: 0;
      transition: color 0.3s ease-out;
    }
  }

  //Selected State
  input[type='radio']:checked {
    ~ div[data-region='answer-label'] {
      @keyframes pulse-glow {
        0% {
          box-shadow: 0 0 14px 3px transparent;
        }
        50% {
          box-shadow: 0 0 14px 3px $primary;
        }
        100% {
          box-shadow: 0 0 14px 3px transparent;
        }
      }
      //Selected border
      &:after {
        opacity: 1;
        background-color: red;
        transform: translate(-50%, -50%) scaleX(1);
        transition: transform 0.3s 0.3s $easeInOutExpo, opacity 0.3s ease-out,
          background-color 0.3s 0.3s ease-out;
        //animation: 0.4s pulse-glow 0.5s linear;
      }

      .answernumber {
        //Selected animating background
        &:before {
          opacity: 1;
          transition: transform 0.5s $easeInExpo, border-radius 0.5s $easeInExpo;
          border-radius: 0;
          transform: translate(-50%, -50%) scale(1);
        }

        //Selected inner glow
        &:after {
          opacity: 1;
          transition: opacity 0.3s 1s ease-out;
        }
      }

      .answernumber + div {
        p {
          color: $white;
        }
      }
    }
  }
}

//Effects only for Quiz Attempty page
&#page-mod-quiz-attempt {
  .answer div[class*='r'] {
    cursor: pointer;
  }

  .answer div[class*='r'] {
    input[type='radio']:checked {
      ~ div[data-region='answer-label'] {
        //Selected border
        &:after {
          animation: 0.4s pulse-glow 0.5s linear;
        }
      }
    }
  }

  .answer div[class*='r']:hover {
    .answernumber {
      background-color: rgba($black, 0.2);
      transition: background-color 0.25s ease-out;
    }
  }
}

//Results
//Hide default result icon (e.g., check and x)
.answer div[class*='r'] {
  div[data-region='answer-label'] + span:has(.icon) {
    display: none;
  }

  input[type='radio'] ~ .icon {
    display: none;
  }
}
.answer div[class*='r'].incorrect,
.answer div[class*='r'].correct {
  input[type='radio'] ~ div[data-region='answer-label'] {
    &:after {
      background-color: transparent;
    }

    .answernumber {
      &:before {
        background-color: transparent;
      }
      &:after {
        box-shadow: none;
      }
    }
    .answernumber + div p {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      &:before {
        font-family: $font-family-icon;
        font-weight: $font-weight-semibold;
        transform: translateY(3px);
      }
    }
  }
}
//Correct
.answer div[class*='r'].correct {
  border-color: $brand-correct;
  outline: 1px solid $brand-correct;

  input[type='radio'] ~ div[data-region='answer-label'] {
    .answernumber + div p {
      color: $brand-correct;

      &:before {
        content: '\f00c'; //check mark
      }
    }
  }
}
//Wrong
.answer div[class*='r'].incorrect {
  border-color: $brand-wrong;
  outline: 1px solid $brand-wrong;

  input[type='radio'] ~ div[data-region='answer-label'] {
    .answernumber + div p {
      color: $brand-wrong;

      &:before {
        content: '\f00d'; //x mark
      }
    }
  }
}

.course-content {
  .tm-card {
    display: block;
    background-color: $brand-dashboard-card-bg;
    border: 1px solid rgba($white, 0.05);
    border-radius: $brand-border-radius;
    overflow: hidden;
    text-decoration: none;
    height: 100%;
    transition:background-color 0.25s ease-out;

    &:hover {
      background-color: lighten($brand-dashboard-card-bg, 10%);

      .tm-card__image img {
        transform: scale(1.1);
      }
    }

    * {
      text-decoration: none;
    }
  }

  .tm-card__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .tm-card__body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .tm-card__image {
    aspect-ratio: 4 / 3;
    max-height: 250px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0;
      transition: transform 0.25s ease-out;
    }
  }

  .tm-card__heading,
  .tm-card__description {
    color: $body-color;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
  }

  .tm-card__heading {
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $primary;
  }
}

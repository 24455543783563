$avatar-size-pc: 200px;
$avatar-size-sp: 75px;
$avatar-border-width-pc: 10px;
$avatar-border-width-sp: 7px;

.userprofile {
  background-color: $gray-900;
}

.userprofile__header {
  background-color: $gray-900;
}

.userprofile__header-inner.main-inner {
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.userprofile__header-image {
  position: relative;
  z-index: 1;
  margin-left: calc(-1 * $avatar-size-pc);
  margin-bottom: calc(-1 * ($avatar-size-pc * 0.5));
  padding: $avatar-border-width-pc; //the black circle border size
  background: $gray-900;
  border-radius: 50%;

  @include media-breakpoint-down(sm) {
    margin-left: calc(-1 * $avatar-size-sp);
    margin-bottom: -24px;
    padding: $avatar-border-width-sp; //the black circle border size
  }

  img {
    margin: 0;
    padding: 0;
    width: $avatar-size-pc;
    height: $avatar-size-pc;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      width: $avatar-size-sp;
      height: $avatar-size-sp;
    }
  }

  .userinitials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size-pc;
    height: $avatar-size-pc;
    margin: 0;
    padding: 0;
    font-size: 80px;

    @include media-breakpoint-down(sm) {
      width: $avatar-size-sp;
      height: $avatar-size-sp;
      font-size: 30px;
    }
  }
}

.userprofile__header-image-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(50% + 20px);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(
      $avatar-size-pc + ($avatar-border-width-pc * 2)
    ); //+[the black circle border size] * 2
    height: calc($avatar-size-pc + ($avatar-border-width-pc * 2));
    border: 1px solid rgba($white, 0.2);
    border-radius: 50%;

    @include media-breakpoint-down(sm) {
      width: calc(
        $avatar-size-sp + ($avatar-border-width-sp * 2)
      ); //+[the black circle border size] * 2
      height: calc($avatar-size-sp + ($avatar-border-width-sp * 2));
    }
  }
}

.userprofile__header-content {
  color: $gray-100;
}

.userprofile__contact {
  background-color: transparent;
  border: 0;
  padding-left: $avatar-size-pc;

  @include media-breakpoint-down(sm) {
    padding-left: $avatar-size-sp;
  }

  .card-body {
    padding: 0;
  }

  .userprofile__header-inner {
    display: flex;
    align-items: flex-end;
    padding-top: 50px;
    padding-bottom: 10px;
    position: relative;
    z-index: 0;

    @include media-breakpoint-down(sm) {
      padding-top: 25px;
      padding-bottom: 0;
    }
  }

  .userprofile__header-inner:before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: $body-bg;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: scaleX(10);
    border-bottom: 1px solid $brand-divider;
  }

  .userprofile__heading-text {
    color: $primary;
    text-transform: uppercase;
    padding-left: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 25px;
      padding-left: 10px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 40px !important;
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
      padding-left: 25px !important;
    }

    li {
      order: 0;
    }

    li.editprofile {
      order: 1;
    }
  }

  .editprofile {
    text-align: left !important;
    margin-top: 20px;
  }
  .editprofile a {
    @include fun-button-primary;
  }
}

.userprofile__body {
  margin-top: 50px;
}

.userprofile__body > .main-inner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: calc(
    $avatar-size-pc + ($avatar-border-width-pc * 4)
  ) !important;

  @include media-breakpoint-down(sm) {
    padding-left: 10px !important;
  }
}

.userprofile__body .card-body {
  color: $gray-100;
}

.userprofile__badges {
  dt {
    display: none;
  }

  ul.badges {
    @include media-breakpoint-down(sm) {
      flex-direction: row;
      justify-content: center;
    }
  }
}

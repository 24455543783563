//Typography
@mixin heading-display-medium {
  font-size: 3.75rem;
  font-weight: $font-weight-black;
  font-style: italic;

  @include media-breakpoint-down(sm) {
    font-size: 1.8765rem;
  }
}
@mixin heading-display-big {
  font-size: 5rem;
  font-weight: $font-weight-black;
  font-style: italic;

  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }

  @include media-breakpoint-between(sm, md) {
    font-size: 4rem;
  }
}

//Fun button effect
@mixin fun-button($icon_url) {
  @include fun-button-style;
  @include fun-button-icon($icon_url);
}
@mixin fun-button-style {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: $primary;
  //box-shadow: 0 0 0px 4px rgba($white, 0.5), 0 0 0px 8px $primary;
  box-shadow: 0 0 0 2px $primary;
  border: 3px solid $white;
  position: relative;
  transition: box-shadow 0.15s ease-out;

  &:hover,
  &:focus {
    box-shadow: none;
    text-decoration: none;
  }
}
@mixin fun-button-text {
  font-family: $font-family-sans-serif;
  font-size: 30px;
  font-weight: $font-weight-bold;
  color: $white;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;

  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}
@mixin fun-button-hooks {
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -20px;
    height: 20px;
    width: 7px;
    height: 20px;
    background-color: $white;
    border: 2px solid $black;
    border-radius: 20px;
    z-index: 0;
    pointer-events: none;
  }

  &:before {
    left: 20px;
  }
  &:after {
    left: inherit;
    right: 20px;
  }
}
@mixin fun-button-icon($icon_url) {
  &:before {
    content: '';
    display: inline-block;
    background: no-repeat center/contain;
    background-image: url($icon_url);
    z-index: 1;
    pointer-events: none;
  }
}

//Specific fun button to default bootstrap buttons
@mixin fun-button-primary {
  @include fun-button-style;
  @include fun-button-text;

  padding: 0.375rem 0.75rem;
  font-size: $font-size-base;
  background-color: $primary;
  border-width: 1px;
  //box-shadow: 0 0 0px 2px rgba($white, 0.5), 0 0 0px 4px $primary;
  box-shadow: 0 0 0 2px $primary;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}
@mixin fun-button-secondary {
  @include fun-button-style;
  @include fun-button-text;

  padding: 0.375rem 0.75rem;
  font-size: $font-size-base;
  color: $black;
  background-color: $secondary;
  border-width: 1px;
  //box-shadow: 0 0 0px 2px rgba($white, 0.5), 0 0 0px 4px $secondary;
  box-shadow: 0 0 0 2px $secondary;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

&#page-badges-mybadges {
  .badges {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .badge-actions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 10px;

    // > *:not(.action-icon) {
    //   display: none;
    // }

    .action-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      text-decoration: none;

      .icon {
        margin: 0;
      }
    }
  }

  .badge-name {
    font-size: 16px;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    margin-top: 10px;
    color: $primary;
  }

  .badge-name--criteria {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;

    span {
      text-align: left;
    }

    i {
      transform: translateY(-1px);
      color: $primary;
    }
  }

  .badges + .box {
    margin: 20px 0 0 !important;
    padding: 0 !important;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .box:first-child {
      display: none;
    }

    //Download All button container
    .box:has(form) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .mform {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  #fgroup_id_searchgroup {
    .felement {
      fieldset {
        > div {
          @include media-breakpoint-down(sm) {
            row-gap: 10px;
          }
        }

        .fitem:first-child {
          @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
          }
        }

        .fitem:nth-child(2) {
          @include media-breakpoint-down(sm) {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.main-inner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @include media-breakpoint-up(md) {
    max-width: 1120px !important;
  }

  @include media-breakpoint-down(sm) {
    overflow-x: hidden;
  }
}

.main-inner,
#page.drawers .main-inner {
  background-color: transparent;

  @include media-breakpoint-down(sm) {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;
  }
}

.main-inner__inner {
  padding-left: 15px;
  padding-right: 15px;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

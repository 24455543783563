#page-header {
  max-width: none !important;

  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }
}

#page-header .page-header__brand {
  font-size: 0.875rem;
  font-weight: $font-weight-black;
  color: $primary;
  text-transform: uppercase;
  padding: 0 10px;
  display: inline-block;
  color: $gray-100;
  background-color: $primary;

  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  }
}

#page-header .page-header__subtitle {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  background-color: rgba($white, 0.8);
  margin-top: 3px;
  padding: 7px 10px;

  @include media-breakpoint-down(sm) {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
}

&.pagelayout-course #page-header .page-context-header .page-header-headings h1 {
  @include heading-display-medium;

  padding: 12px 19px 0 10px;
  color: $primary;
  background-color: rgba($white, 0.8);

  @include media-breakpoint-down(sm) {
    @include heading-display-big;
  }
}

&.pagelayout-incourse #page-header .page-header-image {
  display: none;
}

/**
 * Moodle forms HTML isn't changeable via renderers (yet?) so this
 * .less file imports styles from the bootstrap $variables file and
 * adds them to the existing Moodle form CSS ids and classes.
 *
 */

.jsenabled .mform .containsadvancedelements .advanced {
    display: none;
}

.mform .containsadvancedelements .advanced.show {
    display: flex;
}

#adminsettings span.error {
    display: inline-block;
    border: 1px solid $state-danger-border;
    border-radius: 4px;
    background-color: $state-danger-bg;
    padding: 4px;
    margin-bottom: 4px;
}

.mform .form-inline {
    .form-control,
    .custom-select {
        max-width: 100%;
    }
    textarea.form-control {
        width: 100%;
    }
    .form-group {
        margin: 0.1rem 0.25rem 0.1rem 0;
    }
    br + label {
        justify-content: flex-start;
        width: 100%;
        margin-right: 0;
    }
}

.unresponsive.mform .form-inline,
.unresponsive.mform .form-inline label {
    display: inline-flex;
}

#jump-to-activity.custom-select {
    width: 100%;
}

.mform fieldset {
    margin-bottom: $spacer * 0.5;
    border-bottom: $border-width solid $table-border-color;
}

#adminsettings .form-control[size] {
    width: auto;
}

#adminsettings .error {
    color: $danger;
}

.mform ul.file-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.mform label .req,
.mform label .adv {
    cursor: help;
}
/*rtl:ignore*/
input#id_externalurl {
    direction: ltr;
}

#portfolio-add-button {
    display: inline;
}

.form-defaultinfo,
.form-label .form-shortname {
    color: $text-muted;
}

.form-label .form-shortname {
    font-size: $font-size-xs;
    display: block;
}

.form-item .form-inline {
    display: inline;
}

.form-inline label:not(.sr-only):not(.accesshide) + select {
    margin-left: 0.5rem;
}

.formsettingheading .form-horizontal {
    color: $text-muted;
}

// Moodle doesn't differentiate between what Bootstrap calls
// .uneditable-inputs and form help text. Styling them both as
// uneditable looks ugly, styling both as form help is fairly
// subtle in it's impact. Going for the latter as the best option.
.no-felement.fstatic {
    color: $text-muted;
    padding-top: 5px;
}

.no-fitem .fstaticlabel {
    font-weight: bold;
}

.form-item .form-setting .defaultsnext > input {
    display: inline-block;
}

.form-item .form-setting .form-checkbox.defaultsnext {
    // Need to specify .defaultsnext and the .form-checkbox class
    // is somewhat randomly re-used on various actual checkboxes
    // throughout the admin forms, instead of on the wrapper div.
    margin-top: 5px; // Push down checkboxes to align.
    display: inline-block; // So above style sticks.
}

#adminsettings h3 {
    // Copied from bootstrap/forms.less tag legend.
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: $line-height-base;
    font-size: $font-size-lg;
    line-height: $line-height-base * 2;
    border: 0;
    border-bottom: 1px solid $forms-adminsettings-border-bottom-color;
}

// I think this could be avoided (or at least tidied up) ifr
// we used HTML5 input types like url, phone, email, number etc.
/* rtl:ignore */
.mform .fitem .felement input[name="email"],
.mform .fitem .felement input[name="email2"],
.mform .fitem .felement input[name="url"],
.mform .fitem .felement input[name="idnumber"],
.mform .fitem .felement input[name="phone1"],
.mform .fitem .felement input[name="phone2"] {
    text-align: left;
    direction: ltr;
}

// Reduce the mediaplugin width when using inside forms.
.que.match .mediaplugin {
    width: 50vw;
}

/* rtl:ignore */
#page-admin-grade-edit-scale-edit .error input#id_name {
    margin-right: 170px;
}

#page-grade-edit-outcome-course .courseoutcomes {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#page-grade-edit-outcome-course .courseoutcomes td {
    text-align: center;
}
/* Install Process' text fields Forms, should always be justified to the left */
/* rtl:ignore */
#installform #id_wwwroot,
#installform #id_dirroot,
#installform #id_dataroot,
#installform #id_dbhost,
#installform #id_dbname,
#installform #id_dbuser,
#installform #id_dbpass,
#installform #id_prefix {
    direction: ltr;
}

.mdl-right > label {
    // Workaround for repository pop-up because the : are outside the label,
    // can/should be fixed in filemanager renderers.
    display: inline-block;
}

.singleselect {
    max-width: 100%;
}

.form-item .form-label label {
    margin-bottom: 0;
}

div#dateselector-calendar-panel {
    z-index: 3100; /* Set higher than the z-index of the filemanager - see MDL-39047. */
}

fieldset.coursesearchbox label {
    display: inline;
}

/**
 * Show the labels above text editors and file managers except on wide screens.
 */


/* Section and module editing forms contain special JS components for the
   availability system (if enabled). */
#id_availabilityconditionsjson[aria-hidden=true],
.availability-field [aria-hidden=true] {
    display: none;
}

.availability-field {
    label {
        display: inline-flex;
    }
    .availability-group label {
        vertical-align: top;
    }
}

.availability-eye {
    clear: left;
    float: left;
}
.availability-inner,
.availability-plugincontrols {
    float: left;
    @include border-radius($card-border-radius);
    border: $card-border-width solid $card-border-color;
    padding: 1rem;
    margin-top: 0.5rem;
}
.availability-plugincontrols,
.availability-childlist .availability-inner {
    margin-left: .625rem;
}
.availability-field .availability-plugincontrols .availability-group select {
    max-width: 12rem;
}

/* Custom styles for autocomplete form element */
/* These styles reserve a standard amount of space in the DOM to avoid flicker when the original select element is replaced */
[data-fieldtype=autocomplete] select,
[data-fieldtype=tags] select,
.form-autocomplete-original-select {
    visibility: hidden;
    overflow: hidden;
    width: 15rem;
    height: 44px;
    margin: 0;
    padding: 0;
    border: 0;
    margin-top: $font-size-base * $line-height-base + $input-padding-y-sm;
    vertical-align: bottom;
}
.form-autocomplete-selection {
    margin: $input-padding-y-sm 0;
    // Padding top and bottom, plus mb-1 and the 100% lineheight.
    min-height: 2 * $input-padding-y-sm + 2 * $font-size-base;
}

.form-autocomplete-selection [role=option] {
    cursor: pointer;
    white-space: inherit;
    word-break: break-word;
    line-height: 1.4;
    text-align: left;
}

.form-autocomplete-suggestions {
    position: absolute;
    background-color: $forms-autocomplete-bg;
    border: $border-width solid $input-border-color;
    min-width: 206px;
    max-height: 20em;
    overflow: auto;
    margin: $dropdown-spacer 0 0;
    padding: $dropdown-padding-y 0;
    z-index: 3;
}

.form-autocomplete-suggestions li {
    list-style-type: none;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    margin: 0;
    cursor: pointer;
    color: $body-color;
    &:hover,
    &:focus,
    &[aria-selected="true"] {
        background-color: $dropdown-link-active-bg;
        color: $dropdown-link-active-color;
    }
    &[aria-disabled="true"] {
        pointer-events: none;
        color: $custom-select-disabled-color;
        background-color: $custom-select-disabled-bg;
    }
    &.suggestions-heading {
        pointer-events: none;
        font-weight: bold;
        color: $body-color;
        background-color: $body-bg;
        padding-left: calc(#{$dropdown-item-padding-x} / 2);
    }
    &::before {
        content: "\200B";
    }
}

.form-autocomplete-downarrow {
    color: $body-color;
    top: 0.2rem;
    right: 0.5rem;
    cursor: pointer;
    .loading-icon {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $white;
    }
}

/** Undo some bootstrap things */
.form-autocomplete-selection + input.form-control {
    width: auto;
    display: inline-block;
    vertical-align: middle;
}

.form-autocomplete-selection [data-active-selection=true] {
    box-shadow: $input-btn-focus-box-shadow;
}

select.form-control {
    &[size],
    &[multiple] {
        padding-right: 0;
        option {
            width: fit-content;
        }
    }
}

/* Non-bootstrap selects with a size show their contents outside of the element.
 * Remove when we update to stable bootstrap 4. (MDL-56511) */
select[size],
select[multiple] {
    overflow: auto;
}

select[size="1"] {
    overflow: visible;
}

textarea[data-auto-rows] {
    overflow-x: hidden;
    resize: none;
}

/** Display elements under labels in vertical forms regardless of the screen size. */
.mform.full-width-labels {
    .fitem.row {
        margin-left: 0;
        margin-right: 0;
        & > .col-md-3,
        & > .col-md-9 {
            flex: 0 0 100%;
            max-width: 100%;
            width: inherit;
            padding-right: 0;
            padding-left: 0;
        }
        &.femptylabel > .col-md-3 {
            display: none;
        }
        .form-control {
            width: 100%;
        }
    }
}

.mform .col-form-label .form-label-addon {
    margin-left: 0.25rem;
}

@include media-breakpoint-up(sm) {
    .mform:not(.full-width-labels) .col-form-label .form-label-addon {
        margin-left: auto;
    }
}

/** Allow wrapping an mform in a div with the form-inline class to have an inline, responsive form. */
.form-inline {
    @include media-breakpoint-up(md) {
        .col-md-9,
        .col-md-3 {
            label {
                margin-left: 1rem;
            }
            margin-bottom: 1rem;
            width: auto;
        }
    }
}

[data-fieldtype="modgrade"] .form-group {
    padding-bottom: $input-padding-y;
}

// We dont' use the mixin because it's expensive.
[data-fieldtype="modgrade"] {
    background-color: $card-bg;
    @include border-radius($card-border-radius);
    border: $card-border-width solid $card-border-color;
    padding: $card-spacer-x;
    margin-left: $grid-gutter-width * 0.5;
    max-width: 30rem;
}

// Styles for the JS file types browser provided by the "filetypes" element.
[data-filetypesbrowserbody] {
    [aria-expanded="false"] > [role="group"],
    [aria-expanded="false"] [data-filetypesbrowserfeature="hideifcollapsed"],
    [aria-expanded="true"] [data-filetypesbrowserfeature="hideifexpanded"] {
        display: none;
    }
}

// The autocomplete popup needs a display:block container to correctly position to popup.
.form-inline[data-fieldtype="autocomplete"],
.form-inline[data-fieldtype="tags"] {
    display: block;
}

// Show editor at 100% width by default.
[data-fieldtype="editor"] > div {
    flex-grow: 1;
}

@include media-breakpoint-up(md) {
    .mform fieldset .fcontainer.collapseable .col-form-label {
        padding-left: ($spacer * 2.5);
    }
}

@include media-breakpoint-up(sm) {
    .mform {
        .form-inline {
            .fdefaultcustom {
                label {
                    justify-content: initial;
                }
            }
        }
    }
}

.collapsemenu {
    .collapseall {
        display: block;
    }

    .expandall {
        display: none;
    }

    &.collapsed {
        .collapseall {
            display: none;
        }

        .expandall {
            display: block;
        }
    }
}

// Form inset on the left/right.
// Used to display an icon/button within the form control.
.input-group {
    &.form-inset {
        .form-inset-item {
            position: absolute;
            padding-top: calc(#{$input-padding-y} + #{$input-border-width});
            z-index: 3;
        }

        &.form-inset-left {
            .form-control {
                padding-left: $spacer * 1.5;
            }
        }

        &.form-inset-right {
            .form-control {
                padding-right: $spacer * 1.5;
            }
            .form-inset-item {
                right: 0;
            }
        }
    }
}

// For form elements aligned to the left with no padding (e.g. Completion conditions activity settings).
.form-check {
    &.left-indented {
        padding-left: 0;
    }
}

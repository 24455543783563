.page-context-header {
  align-items: center;
  overflow: visible;

  .page-header-image img,
  .page-header-image .userinitials {
    width: 50px;
    height: 50px;
    font-size: 16px;

    @include media-breakpoint-down(sm) {
      width: 40px;
      height: 40px;
      font-size: 14px;
    }
  }

  h1 {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  .header-button-group {
    display: none;
  }
}

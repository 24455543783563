//All Sections
.course-content ul.topics li.section.course-section:has(.activity-completion) {
  padding-bottom: 0;
}
.topics .activity-item .activity-completion {
  grid-area: availability;
  margin: 50px 0 10px;
}

//Topics divider
.topics .course-section {
  border-color: $brand-divider;
}

//Remove default border for topics with [Mark as done] button (i.e., no .activityinline class)
.topics .activity-item {
  border: 0;
}

//Hide Announcements
.topics
  .course-section:first-child
  .activity.activity-wrapper.forum.modtype_forum {
  display: none;
}

//Summary Section
.topics .course-section:first-child {
  border-bottom: 0;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding: 0;
    background-color: rgba($white, 0.05);
  }

  //borders & bg
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: calc((100% - 100vw) * 0.5);
    width: calc(100vw - 8px);
    height: 100%;
    pointer-events: none;

    @include media-breakpoint-down(sm) {
      left: -8px;
      width: calc(100vw + 16px);
      background-color: none;
    }
  }
  &:before {
    background-color: rgba($black, 0.08);
  }
  &:after {
    border: 1px solid $brand-divider;
    border-left: 0;
    border-right: 0;
  }

  .course-section-header {
    display: none !important;
  }

  div[data-for='sectioninfo'] {
    display: none;
  }

  .activity-item {
    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }

  .activity-item .activity-altcontent .no-overflow {
    overflow: hidden;
  }

  .activity-altcontent {
    .row .col {
      @include media-breakpoint-down(sm) {
        flex: 0 0 100%;
      }
    }

    .row {
      @include media-breakpoint-down(sm) {
        row-gap: 20px;
      }
    }

    //badges area
    // .row .col:nth-child(1) {
    //   flex: 0 0 30%;
    //   display: flex;
    //   flex-wrap: wrap;
    //   align-items: flex-start;
    //   row-gap: 10px;

    //   @include media-breakpoint-down(sm) {
    //     flex: 0 0 100%;
    //   }

    //   img {
    //     width: calc(50% - 10px);
    //     object-fit: contain;
    //     margin: 0 5px;
    //   }
    // }
    .row .col:nth-child(1) .topics__badges.row {      
      row-gap: 10px;
      margin: 0 -5px;

      > .col {
        padding: 0 5px;
      }

      img {        
        aspect-ratio: 1;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
}

//Images
.topics .course-section:not(:first-child) {
  .activity-item .activity-altcontent p img {
    max-height: 500px;
    object-fit: contain;

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-height: 300px;
    }
  }
}

//Videos
.topics .activity-item .activity-altcontent .mediaplugin.mediaplugin_videojs {
  > div {
    max-width: none !important;
  }

  //   .vjs-big-play-button {
  //     border-radius: 50%;
  //     width: 150px;
  //     height: 150px;
  //     background-color: rgba(0, 0, 0, 0.5);
  //     display: flex;
  //     align-items: center;
  //     border: 2px solid white;
  //     top: calc(50% - 30px);
  //   }

  //   .vjs-icon-placeholder {
  //     font-size: 80px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);

  //     &:before {
  //       position: static;
  //     }
  //   }
}

//Quiz
.topics .course-section:last-child .course-section-header {
  display: none !important;
}
.topics .quiz {
  margin-top: 50px;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }

  .activity-item {
    border: 0;
  }

  .activity-icon {
    display: none;
  }

  .activitytitle {
    align-self: center !important;
  }

  .activityname a {
    @include fun-button-style;
    @include fun-button-hooks;

    .instancename {
      @include fun-button-icon('../../pix/icon-quiz.svg');
      &:before {
        width: 50px;
        height: 50px;

        @include media-breakpoint-down(sm) {
          width: 30px;
          height: 30px;
        }
      }

      @include fun-button-text;
      font-size: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }
  }

  .activity-completion {
    display: none;
  }
}

.topics {
  .sectionname > a {
    font-size: 30px;
    color: $primary;
    text-transform: uppercase;
    pointer-events: none;
  }

  .course-section:not(:first-child) {
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }
  }
}

table,
table[class="*"],
table.quizsummaryofattempt,
table.quizattemptsummary,
table.quizreviewsummary,
table.generaltable {
  margin-top: 20px;
  border: 1px solid $brand-divider;

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }

  .quizsummaryheading {
    display: none;
  }

  thead {
    th {
      border-color: transparent;
      background-color: $primary;
      color: $white;
      text-transform: uppercase;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        padding: 10px;
        font-size: 12px;
      }
    }

    th a {
      color: $white;
    }
  }

  tbody {

    tr,
    td {
      background-color: transparent !important;
    }

    th {
      border-color: $black;
      background-color: $primary;
      color: $white;
      text-transform: uppercase;
    }

    td {
      border-color: $brand-divider;
    }

    th,
    td {
      @include media-breakpoint-down(sm) {
        padding: 10px;
        font-size: 12px;
      }
    }

    .notyetanswered {
      color: $danger;

      &:hover {
        color: $danger;
      }
    }

    .statedetails {
      color: $brand-text-gray;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }
    }
  }
}

//Remove grade row
.quizreviewsummary tbody tr:last-child {
  display: none;
}

//Remove time taken
.quizreviewsummary tbody tr:nth-child(4) {
  display: none;
}

//Hide Attempt header (for space optimization)
.quizattemptsummary {
  thead th:first-child {
    font-size: 0;
  }
}

//Remove grade column
.quizattemptsummary:has(.c3) {

  th:nth-last-child(2),
  td:nth-last-child(2) {
    display: none;
  }
}

//Fix border for bestrow
&#page-mod-quiz-view table.quizattemptsummary tr.bestrow td {
  border-color: $brand-divider;
}

//Remove Highest grade feedback
&#page-mod-quiz-view #feedback {
  display: none;
}

//Grades page
.user-report-container {
  background-color: transparent;
}

//Site Announcements page
table.discussion-list {
  thead {
    th {
      padding: 0.75rem !important;
    }

    th.lastpost {
      .icon {
        color: $brand-text-black;
        font-size: 12px;
        vertical-align: middle;
      }
    }
  }

  tbody {
    th {
      background-color: transparent;
      border-color: $brand-divider;
    }

    th a {
      color: $body-color;
      font-weight: $font-weight-semibold;

      @include media-breakpoint-down(sm) {
        min-width: 100px !important;
      }
    }

    th {
      >div {
        @include media-breakpoint-down(sm) {
          padding: 5px !important;
        }
      }
    }

    td:first-child {
      .btn.btn-link {
        @include media-breakpoint-down(sm) {
          padding: 0;
        }

        .icon {
          @include media-breakpoint-down(sm) {
            font-size: 10px;
          }
        }
      }
    }
  }

  thead,
  tbody {

    //Hide: Star, Last Post, Replies, Subscribe, Actions
    th,
    td {

      &:first-child,
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:last-child {
        display: none;
      }
    }

    //Show only date on Started By column
    th:nth-child(3) {
      font-size: 0;
    }

    td:nth-child(3) {
      vertical-align: top !important;
      padding-top: 18px;

      .userpicture {
        display: none;
      }

      .author-info {
        >div:first-child {
          display: none;
        }
      }
    }
  }
}
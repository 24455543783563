.forumpost {
    padding: 15px !important;

    .header {
        >*:first-child:has(img) {
            display: none;
        }

        h3+div {
            font-size: 0;
        }

        time {
            font-size: 12px;
            color: $brand-text-gray;
            margin-top: 5px;
            display: block;
        }
    }

    .author-groups-container,
    .post-actions {
        display: none !important;
    }
}
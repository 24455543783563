//Default bootstrap buttons
button.btn.btn-primary,
input[type='submit'].btn.btn-primary,
.btn.btn-primary.btn-fun {
  @include fun-button-primary;
}

button.btn.btn-secondary,
input[type='submit'].btn.btn-secondary,
.notification-area .toggle-mode .btn.btn-link {
  @include fun-button-secondary;
}

a.btn.btn-secondary {
  @include fun-button-secondary;
}

button:not(.dropdown-toggle),
input[type='submit'],
.btn[data-toggle='popover'] {
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

.btn.btn-icon {
  &:hover,
  &:focus {
    background-color: $brand-transparent-hover-bg;
  }
}

//Consistent close buttons
button[data-action*='close'],
a[data-action*='close'],
button[class*='close'],
button.closebutton {
  .icon,
  span[aria-hidden] {
    display: none;
  }
  position: relative;
  z-index: 0;

  //X icon
  &:before {
    content: '\f00d'; //fa-xmark
    font-family: $font-family-icon;
    font-weight: $font-weight-semibold;
    color: $white;
  }
  //Hover effect
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    aspect-ratio: 1 / 1;
    transform: translate(-50%, -50%);
    background-color: rgba($white, 0.05);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.25s ease-out;
    z-index: -1;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}
.alert button.close {
  &:before {
    color: $black;
  }
}

.modal .close {
  color: $white;
  text-shadow: none;
  opacity: 1;
}

//Accordion buttons
.btn.icons-collapse-expand {
  transform: scale(0.8) translateY(-5px);

  &:focus {
    background-color: transparent;
  }
}

//Quiz submit button on quiz page
&.pagelayout-incourse .submitbtns:has(input.mod_quiz-next-nav) {
  @include fun-button-style;

  display: inline-flex !important;
  padding: 0;
  align-self: flex-end;
  margin-top: 80px;
  margin-right: 4px;

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}
&.pagelayout-incourse input[type='submit'].mod_quiz-next-nav {
  @include fun-button-text;
  box-shadow: none;
  border: 0;
}

//Continue your attempt button on initial quiz view page
&.pagelayout-incourse#page-mod-quiz-view
  .quizstartbuttondiv
  button[type='submit'] {
  @include fun-button-style;
  @include fun-button-text;
}

//Submit button on quiz summary view page
&.pagelayout-incourse#page-mod-quiz-summary
  .btn-finishattempt
  button[type='submit'] {
  @include fun-button-style;
  @include fun-button-text;
}

//Return to attempt button on quiz summary view page
&.pagelayout-incourse#page-mod-quiz-summary
  .submitbtns
  .singlebutton
  button[type='submit'] {
  &:focus {
    box-shadow: none;
  }

  &:before {
    content: '\f053';
    font-size: 12px;
    font-family: $font-family-icon;
    font-weight: $font-weight-semibold;
    display: inline-block;
    transform: translateY(1px);
  }
}

//Finish review button on quiz review page
&.pagelayout-incourse#page-mod-quiz-review .submitbtns .mod_quiz-next-nav {
  @include fun-button-style;
  @include fun-button-text;

  padding: 10px 20px;

  @include media-breakpoint-down(sm) {
    margin-left: auto;
    margin-right: auto;
  }
}

//Mark as done button
.activity-item
  .activity-completion
  button[data-action='toggle-manual-completion'] {
  @include fun-button-secondary;

  min-height: 38px;

  &.btn.btn-success[data-toggletype='manual:undo'] {
    color: $brand-text-black;
    background-color: $primary;
    border-color: $primary;
    box-shadow: 0 0 0 2px #000, 0 0 0 4px $primary;

    &:active {
      background-color: lighten($primary, 10%);
      border-color: lighten($primary, 10%);
    }
  }
}

//Lightbox button
.lightbox-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  z-index: 10;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

//Popover criteria button
.popover-criteria-button {
  padding: 0 5px;

  i {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
.message-app {
  background-color: $brand-popup-bg;

  .card {
    background-color: transparent;
  }

  .card-header {
    border-color: $brand-divider;
  }

  .card-header .btn {
    color: $white;

    &:focus {
      box-shadow: none;
    }
  }

  .list-group .list-group-item {
    background-color: transparent;

    &:hover {
      background-color: rgba($white, 0.1);
    }
  }

  .list-group-item-action {
    color: $white;
  }

  .input-group .btn-submit {
    color: $black;
    background-color: $gray-500;
  }

  a[data-route="view-contacts"] {
    font-size: 12px;
    font-weight: $font-weight-semibold;

    .icon {
      font-size: 12px;
    }
  }

  a[data-route="view-settings"] {
    color: $white;
  }

  a[data-toggle="tab"].active {
    color: $black;
  }

  div[data-region="view-settings"] {
    background-color: $brand-popup-bg !important;
    border-color: $brand-divider !important;

    h3,
    p,
    label {
      color: $white;
    }
  }

  .footer-container {
    display: none;
  }

  .header-container {
    color: $white;
  }
}

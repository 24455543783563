.usermenu {
  .dropdown-toggle {
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-divider {
    border-color: $brand-divider;
  }

  .dropdown-menu {
    top: calc(100% - 2px);
    border-radius: 0 0 4px 4px;
    border-top: 0;
    background-color: $brand-navbar-bg;
  }
}

//Bottom vertical spacing allowance for the Drawer Left Toggle to not cover content
#page {
  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }
}

#page div[role="main"] {
  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }
}

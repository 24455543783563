&#page-user-profile {
  background-color: $black;

  #page {
    padding: 0;
    border: 1px solid transparent;
    overflow-x: hidden;
  }

  #page-header {
    display: none;
  }

  #page.drawers div[role="main"] {
    padding: 0;
  }

  #region-main > {
    .main-content,
    .main-content > .main-inner {
      padding: 0;
      margin: 0;
      max-width: none !important;
    }
  }
}

.breadcrumb {
  @include media-breakpoint-down(sm) {
    margin-bottom: 0 !important;
    padding-bottom: 0;
  }
}

//Site Announcements page breadcrumb
&#page-mod-forum-view {
  .breadcrumb {
    opacity: 0;
    pointer-events: none;
  }
}

//Hide homepage breadcrumb
.breadcrumb:has(a[href*="redirect=0"]) {
  .breadcrumb-item:first-child {
    display: none;
  }
  .breadcrumb-item:nth-child(2) {
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}

.performance-bars {
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  row-gap: $pb_row_gap;

  @media screen and (max-width: 576px) {
    width: 100%;
  }

  .performance-bars__item {
    position: relative;
    padding: $pb_item_v_padding $pb_item_h_padding;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    column-gap: $pb_item_column_gap;
    row-gap: $pb_item_row_gap;
    z-index: 0;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .performance-bars__item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: $pb_item_bg;
    border: $pb_item_border;
    border-radius: $pb_item_radius;

    z-index: -1;
  }

  .performance-bars__icon {
    flex: 0 0 auto;
    width: $pb_icon_size;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 1;
    }
  }

  .performance-bars__title {
    flex: 1 1 $pb_title_width;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    margin: 0;
    line-height: 1.2;
    min-width: 165px;
  }

  .performance-bars__bar {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    column-gap: $pb_block_column_gap;
    justify-content: flex-end;
    height: 100%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .performance-bars__block {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    width: $pb_block_width;
    height: $pb_block_height;
    margin: 0;
    border: 0;
    border-radius: $pb_block_radius;
    overflow: hidden;
    transform: skew($pb_block_skew);

    // @include media-breakpoint-down(sm) {
    //   flex: 1 1 auto;
    // }

    @media screen and (max-width: 375px) {
      width: calc(#{$pb_block_width} * 0.7);
      height: auto;
      aspect-ratio: 1 / 1.2;
    }

    //border
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: $pb_block_radius;
      overflow: hidden;
    }

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        opacity: $i / 10;
      }
    }
  }

  .performance-bars__block--active {
    background-color: $pb_block_active_bg;
    &:after {
      border: $pb_block_active_border;
    }
  }

  .performance-bars__block:not(.performance-bars__block--active) {
    background-color: $pb_block_inactive_bg;
    &:after {
      border: $pb_block_inactive_border;
    }

    .performance-bars__overlay {
      display: none;
    }
  }

  .performance-bars__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fef3e1;
  }
}

//Modifiers
//performance-bars--with-icon
.performance-bars.performance-bars--with-icon {
  display: flex;

  .performance-bars__title {
    @include media-breakpoint-down(sm) {
      padding-left: calc(#{$pb_icon_size} + #{$pb_item_column_gap});
    }
  }

  .performance-bars__title {
    @include media-breakpoint-down(sm) {
      //margin-right: calc(-1 * (#{$pb_icon_size} + #{$pb_item_column_gap}));
    }
  }

  .performance-bars__bar {
    @include media-breakpoint-down(sm) {
      padding-left: calc(#{$pb_icon_size} + #{$pb_item_column_gap});
    }
  }

  .performance-bars__item {
    @include media-breakpoint-down(sm) {
      position: relative;
    }
  }

  .performance-bars__icon {
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 0;
      padding-bottom: $pb_item_v_padding;
    }
  }
}

//performance-bars--in-listing
.performance-bars.performance-bars--in-listing {
  margin-top: 0;
  row-gap: 0;

  .performance-bars__item-bg {
    border: 0;
  }

  .performance-bars__item {
    padding: 5px 0;
  }

  .performance-bars__title {
    font-size: 14px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }

  .performance-bars__block {
    font-size: 13px;
    border: 2px solid yellow;

    @include media-breakpoint-up(md) {
      width: calc(#{$pb_block_width} - 4px);
      height: calc(#{$pb_block_width} - 4px);
      border-radius: calc(#{$pb_block_radius} / 2);
      font-size: 12px;
    }
  }
}
.performance-bars.performance-bars--in-listing.performance-bars--with-icon {
  .performance-bars__icon {
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
    }
  }
}

//performance-bars--roadx
.performance-bars.performance-bars--roadx {
  .performance-bars__item-bg {
    transform: skew($pb_block_skew);
  }
}

/**
 * Dropdown menu Moodle specific styles.
 */

// Make links in a menu clickable anywhere in the row.
.dropdown-item {
    a {
        display: block;
        width: 100%;
        color: $body-color;
    }
    &.active,
    &:active,
    &:hover,
    &:focus,
    &:focus-within {
        outline: 0;
        background-color: $dropdown-link-active-bg;
        color: $dropdown-link-active-color;
        a {
            color: $dropdown-link-active-color;
        }
    }
    &[aria-current="true"],
    &[aria-selected="true"] {
        position: relative;
        display: flex;
        align-items: center;
        &:before {
            @extend .fa-solid;
            content: fa-content($fa-var-check);
            position: absolute;
            left: 0.4rem;
            font-size: 0.7rem;
        }
    }
}

.dropdown-menu {
    width: fit-content;
}

// Add dropdown menu items styles for each theme color (mantainning default hover colour for contrast).
@each $color, $value in $theme-colors {
    .dropdown-item.text-#{$color} {
        color: $value;
        &:hover {
            color: $dropdown-link-hover-color !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

.course-header {
  //border: 1px solid red;
}

#page.drawers .course-header > .main-inner {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  //border: 1px solid blue;
}

#page.drawers .course-header > .main-inner > .main-inner__inner {
  //border: 1px solid orange;
}

.course-header__inner {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.course-header__image {
  position: absolute;
  width: 68%;
  left: 0;
  top: -90px;

  @include media-breakpoint-down(sm) {
    position: static;
    width: 100%;
    min-height: 200px;
    margin-bottom: -40%;
  }
}

.course-header__image img {
  width: 100%;
}

.course-header__header {
  position: relative;
  z-index: 0;

  //contrast shadow fix in case the heading overlaps with the tire image
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(90deg, transparent, $brand-content-bg);
    display: none;

    @include media-breakpoint-down(sm) {
      background: linear-gradient(180deg, transparent, $brand-content-bg);
      height: 200%;
      top: -100%;
    }
  }
}

.course-header__header #page-header .main-inner {
  padding-right: 0;
  margin-right: 0;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.course-header__header #page-header .main-inner__inner {
  padding-right: 0;
  margin-right: 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.dropdown {
  > button.dropdown-toggle {
    background-color: $input-bg;
    border-color: $input-border-color;
    color: $brand-content-text;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      background-color: $input-bg;
      border-color: $input-border-color;
      color: $brand-content-text;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: 0 0 0 2px $input-btn-focus-color;
      border-color: $input-focus-border-color;
    }

    &:after {
      content: "\f078"; //chevron-down
      font-family: $font-family-icon;
      border: 0;
      color: $brand-content-text;
    }

    span[data-active-item-text] {
      color: $brand-content-text;
    }
  }

  > .dropdown-menu {
    background-color: $brand-popup-bg;
    border-color: $brand-divider;

    .dropdown-item {
      background-color: transparent;
      color: $brand-content-text;

      &:hover {
        background-color: $brand-transparent-hover-bg;
      }

      &:focus,
      &:active {
        background-color: $brand-transparent-hover-bg;
      }
    }
  }
}

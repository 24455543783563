/*
User Profile Blocks (hidden via renderer classes/output/core_user/myprofile/renderer.php)

1. Reports
2. Course Details
3. Miscelleneous (e.g., blogs, forums)
*/

/*
Dashboard Course Overview (disabled via Admin Preset: block_myoverview)

1. Card Layout options
2. Filter options
*/

/*
Right drawer (e.g., dashboard, quiz) (removed via drawers.mustache template)
*/

/*
Hide Debugging Information on Footer

Debugging information is already disabled on $CFG. Hiding it visually is fallback for user theme.
*/
.footer-popover .footer-content-debugging {
  display: none;
}

/*
Edit User Profile
*/
&#page-user-edit {
  //general fields [MoodleNet profile ID, Description, Picture description]
  #fitem_id_moodlenetprofile,
  #fitem_id_description_editor,
  #fitem_id_imagealt {
    display: none;
  }

  //other sections [Additional Names (e.g., alternate name), Interests (e.g., user tags), Optional fields (e.g. addres, phone, department) ]
  #id_moodle_additional_names,
  #id_moodle_interests,
  #id_moodle_optional {
    display: none;
  }
}

/*
File Manager
*/
.filemanager {
  //file & directory viewing
  .fp-pathbar,
  .fp-viewbar {
    display: none;
  }

  //file & directory actions (no need for delete icon as clicking on the image accesses the upload edit action)
  .fp-btn-mkdir,
  .fp-btn-delete {
    display: none;
  }
}

/*
User Preferences
*/
&#page-user-preferences {
  //user account block
  a[href*="editor"],
  a[href*="calendar"],
  a[href*="contentbank"] {
    display: none;
  }

  //blogs block
  div[class*="col"]:has(a[href*="/blog"]) {
    display: none;
  }
}

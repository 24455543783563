//Remove ugly focus effect
.modal-dialog.focus,
.modal-dialog:focus {
  .modal-content {
    box-shadow: none !important;
  }
}

.modal-content {
  padding: 20px;
  border: 1px solid $brand-popup-border;
  background-color: $brand-popup-bg;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 0;
  border: 0;
}

.modal-header {
  margin-bottom: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
}

.modal-title {
  text-transform: uppercase;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-top: 0 !important;
}

//File Manager
.filemanager {
  ~ p {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: $font-weight-semibold;
  }

  ~ .form-filetypes-descriptions {
    small {
      font-weight: $font-weight-semibold;
      color: $brand-content-text !important;
    }
  }

  .fp-restrictions {
    margin-bottom: 5px;
  }

  .dndupload-message {
    display: inline;
    color: $black;

    .dndupload-arrow {
      @include media-breakpoint-down(sm) {
        top: -7px;
        left: 0;
      }
    }
  }

  .fa.fa-arrow-circle-o-down {
    font-weight: normal;
  }

  .fp-file > .aabtn {
    color: $black;
  }

  .fp-toolbar {
    .btn {
      box-shadow: none;
    }
  }

  .fm-empty-container {
    @include media-breakpoint-down(sm) {
      padding: 60px 20px 0 !important;
    }
  }
}

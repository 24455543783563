.course-content {
  .news {
    overflow: hidden;
  }

  ul.news__list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: -10px;
  }

  .news__list-item {
    flex: 0 0 33.333333%;
    padding: 10px;

    @include media-breakpoint-down(md) {
      flex: 0 0 50%;
    }

    @include media-breakpoint-down(xsm) {
      flex: 0 0 100%;
    }
  }
}

[data-region="loading-placeholder-content"] {
  .media-list {
    padding-left: 0;

    li.media {
      position: relative;
      padding: 5px 15px;

      &:not(:first-child) {
        border-top: 1px dashed $brand-divider;
      }

      .media-body {
        max-width: none !important;
        opacity: 0.3;

        .rounded-circle {
          aspect-ratio: 1;

          + * {
            border-radius: 10px;
          }
        }
      }
    }
  }
}

&#page-login-index,
&#page-login-forgot_password,
&#page-login-signup {
  #page {
    background: $body-bg;
    background-size: cover;
    background-position: center;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }

  #page-content {
    width: 400px;

    @include media-breakpoint-down(sm) {
      width: 280px;
    }
  }

  #region-main {
    background-color: transparent;
    padding: 0;
  }
}

&#page-login-forgot_password {
  #page-content {
    width: 600px;

    @include media-breakpoint-down(sm) {
      width: 280px;
    }
  }
}

&#page-login-signup {
  #page {
    height: auto;
  }

  #page-content {
    width: auto;
    max-width: 600px;
    padding: 50px 0;

    @include media-breakpoint-down(sm) {
      width: auto;
      max-width: 320px;
    }
  }
}

.login-wrapper {
  .login-container {
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: 100% !important;
    box-shadow: none;
  }

  div[role='main'] {
    padding-bottom: 0;
  }
}

//References:
//https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
//https://github.com/twbs/bootstrap/blob/main/scss/_maps.scss
//https://github.com/twbs/bootstrap/blob/main/scss/_root.scss
//wwwroot/theme/boost/scss/bootstrap/_variables.scss

//---------------- Bootstrap swatches ----------------//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #8f959e !default;
$gray-600: #6a737b !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #1d2125 !default;
$black: #000 !default;

$blue: #0f6cbf !default;
$indigo: #6610f2 !default;
$purple: #613d7c !default;
$pink: #e83e8c !default;
$red: #ca3120 !default;
$orange: #f0ad4e !default;
$yellow: #ff7518 !default;
$green: #357a32 !default;
$teal: #20c997 !default;
$cyan: #008196 !default;

//This theme's brand color overrides
$yellow: #fee501;
$orange: #ff7518;
$red: #c72127;

//Main branding accent color
$primary: $red;

//Secondary color visible in secondary buttons (e.g., cancel, back)
$secondary: $gray-400;

//State
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$info-outline: #1f7e9a;
$warning-outline: #a6670e;

// stylelint-disable
$theme-colors: ();
$theme-colors: map-merge(
  (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger
  ),
  $theme-colors
);
// stylelint-enable

//---------------- Start of this theme's custom variables ----------------//
//Body default colors
$brand-content-bg: $white;
$brand-content-text: $black;

//Navbar colors
$brand-navbar-bg: $white;
$brand-navbar-border: rgba($black, 0.2);
$brand-navbar-active-color: $white;
$brand-navbar-active-border: $primary;

//Progess colors
$brand-course-track: $black;
$brand-course-no-progress: $gray-500;
$brand-course-in-progress: #969ca5;
$brand-course-complete: $primary;

//Text colors
$brand-text-black: $black;
$brand-text-gray: $gray-500;

//Section colors
$brand-section-bg: rgba($white, 0.05);
$brand-section-border: rgba($white, 0.1);
$brand-dashboard-content-overlay: rgba($black, 0.9);

//
$brand-transparent-hover-bg: rgba($black, 0.05);

//
$brand-left-sidebar-bg: #202020;

//Cards
$brand-dashboard-card-bg: #141414;

//Colors for popups (e.g., dropdown, message, popovers, dialog)
$brand-popup-bg: $gray-100;
$brand-popup-border: $gray-200;
$brand-popup-footer-bg: $gray-300;

//Divider line colors
$brand-divider: $brand-navbar-border;

//Sitewide border radius
$brand-border-radius: 4px;

//Drawer colors
$drawer-toggle-bg: $primary;
$drawer-toggle-icon-color: $white;

//Quiz colors
$brand-correct: $success;
$brand-wrong: $danger;

//Easings
$easeInExpo: cubic-bezier(0.7, 0, 0.84, 0);
$easeOutExpo: cubic-bezier(0.16, 1, 0.3, 1);
$easeInOutExpo: cubic-bezier(0.87, 0, 0.13, 1);
$easeOutBack: cubic-bezier(0.34, 1.56, 0.64, 1);
//---------------- End of this theme's custom variables ----------------//

//---------------- Bootstrap variables ----------------
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 2
  ),
  6: (
    $spacer * 3
  )
) !default;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  xsm: 430px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// Body
$body-bg: $brand-content-bg;
$body-color: $brand-content-text;

// Breadcrumbs
$breadcrumb-padding-y: 0.5rem !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding: 0.5rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: transparent !default;
$breadcrumb-divider: '/' !default;
$breadcrumb-divider-rtl: '/' !default;

// Alerts
$alert-border-width: 0 !default;

// Typography
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-icon: 'Font Awesome 6 Free';

$headings-font-family: 'industry', sans-serif;
$headings-line-height: 1;
$headings-font-weight: 900;

$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;
$font-weight-book: 500;

// Forms
$input-color: $body-color;
$input-bg: $body-bg;
$input-border-color: rgba($black, 0.5);
$input-btn-focus-color: rgba($primary, 0.75) !default;

// Modals
$modal-content-bg: $gray-900;

// Performance bars

$pb_icon_size: 30px;
$pb_title_width: 35%;
$pb_row_gap: 10px;

$pb_block_active_bg: #c91328;
$pb_block_active_border: 1px solid rgba(#000, 0.2);
$pb_block_inactive_bg: transparent;
$pb_block_inactive_border: 1px solid #606164;
$pb_block_width: 25px;
$pb_block_height: 25px;
$pb_block_radius: 5px;
$pb_block_column_gap: 4px;
$pb_block_skew: -10deg;

$pb_item_bg: transparent;
$pb_item_border: 1px solid rgba(#000, 0.7);
$pb_item_radius: 4px;
$pb_item_h_padding: 20px;
$pb_item_v_padding: 10px;
$pb_item_column_gap: 20px;
$pb_item_row_gap: 5px;

.gallery {
  display: flex;
  flex-wrap: wrap;

  > * {
    border: 1px solid rgba($black, 0.1);
    border-radius: 0 !important;
    width: calc(33.33333% - 20px);
    padding: 2px;
    margin: 10px;
    background-color: rgba($black, 0.05);
    transition: border-color 0.25s ease-out, background-color 0.25s ease-out;

    @include media-breakpoint-down(sm) {
      width: calc(50% - 20px);
    }

    &:hover {
      border-color: rgba($black, 0.15);
      background-color: rgba($black, 0.1);
    }
  }
}

.gallery + .gallery {
  margin-top: 50px;
}

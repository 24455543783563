&#page-user-edit {
  div[role="main"] > h2 {
    display: none;
  }

  //Hide unnecessary fields
  #fitem_id_moodlenetprofile,
  #fitem_id_description_editor,
  #fitem_id_imagealt {
    display: none;
  }

  //Hide unnecessary sections
  #id_moodle_additional_names,
  #id_moodle_interests,
  #id_moodle_optional {
    display: none;
  }

  .mform {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .fcontainer {
      @include media-breakpoint-down(sm) {
        padding: 0 10px;
      }
    }
  }
}

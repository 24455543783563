.navbar.fixed-top {
  background-color: $brand-navbar-bg !important;
  border-bottom-color: $brand-navbar-border;
  align-items: normal;
  padding: 0 20px;

  @include media-breakpoint-down(sm) {
    padding: 0 10px;
  }

  //borders
  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $brand-section-border;
  }

  > * {
    //border: 1px solid red !important;
  }
}

.nav {
  column-gap: 10px;
}

.navbar-brand {
  @include media-breakpoint-down(sm) {
    display: flex !important;
    flex: 0 0 30%;
    margin: 0 0 0 10px !important;
  }

  img {
    width: 200px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}

.navbar-toggler {
  .navbar-toggler-icon {
    background-image: none;
    position: relative;
    display: block;
    width: 22px;
    height: 18px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: $red;
      position: absolute;
      left: 0;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 8px;
    }

    span:nth-child(3) {
      bottom: 0;
    }
  }
}

.navbar .primary-navigation .navigation .nav-link {
  font-size: 12px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $black;
  border: 0;
  position: relative;

  //borders
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: calc(100% - 20px);
    border: 1px solid $brand-navbar-active-border;
    border-radius: 4px;
    opacity: 0;
    transform: scaleX(0);
    transition: transform 0s 0.3s, opacity 0.25s ease-out;
    z-index: -1;
  }

  &:focus {
    box-shadow: none;
    background-color: transparent !important;
  }

  &:hover {
    background-color: transparent;
    color: $black;

    &:before {
      transition: transform 0.3s $easeInOutExpo;
      opacity: 1;
      transform: scaleX(1);
    }
  }

  &.active {
    color: $brand-navbar-active-color;

    &:before {
      opacity: 1;
      background-color: $primary;
      transform: none;
    }
  }
}

.navbar.navbar-dark .icon,
.navbar.navbar-dark a .icon {
  color: $brand-navbar-active-color !important;
}

.badge-svg svg {
  width: 100%;  
  height: auto;
}

a:has(.badge-svg--earned) .badge-svg *[id*='js-'] {
  opacity: 0;
}

a:has(.badge-svg--unearned) {
  pointer-events: none;

  ~ .badge-actions {
    opacity: 0;
    pointer-events: none;
  }

  .badge-svg--unearned {
    opacity: 0.2;
  }

  .badge-name,
  ~ .badge-name,
  ~ .badge-name--criteria i {
    color: $secondary !important;
  }
}

a:has(.badge-svg--earned) {
  .badge-name,
  ~ .badge-name,
  ~ .badge-name--criteria i {
    color: $primary !important;
  }
}

// If placeholder image is shown
.badges li img.badge-image {
  width: 100%;
  height: auto;
}

@mixin toast-icon($content) {
    @extend .fa-solid;
    margin: 2px 5px 0 0;
    content: fa-content($content);
}

@mixin toast-variant($color) {
    background-color: rgba(theme-color-level($color, $alert-bg-level), .95);
    color: theme-color-level($color, $alert-color-level);
    .toast-header {
        color: theme-color-level($color, $alert-color-level);
    }
}

.toast {
    border-radius: $toast-border-radius;

    &.toast-success {
        @include toast-variant('success');
        .toast-body:before {
            @include toast-icon($fa-var-check-circle);
        }
    }

    &.toast-danger {
        @include toast-variant('danger');
        .toast-body:before {
            @include toast-icon($fa-var-times-circle);
        }
    }

    &.toast-info {
        @include toast-variant('info');
        .toast-body:before {
            @include toast-icon($fa-var-info-circle);
        }
    }

    &.toast-warning {
        @include toast-variant('warning');
        .toast-body:before {
            @include toast-icon($fa-var-exclamation-circle);
        }
    }

    .close {
        color: inherit;
    }
}

&#page-badges-badge {
  form#downloadbadgeform {
    margin-top: 20px;
    text-align: center;

    .btn {
      width: auto !important;
    }
  }

  h2 {
    color: $primary;
  }

  h3 {
    color: $body-color;
  }

  #badge-image-col img {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  #badge-details-col {
    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    h2 {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
  }

  #badge-awardedto {
    padding-bottom: 0 !important;
    font-size: 16px;
    font-weight: $font-weight-semibold;
    color: $primary;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  #badge-issued-expire {
    padding-top: 0 !important;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    small {
      color: $brand-text-gray;
    }

    div:nth-child(2) {
      font-weight: $font-weight-black;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}

.moodle-dialogue-base .moodle-dialogue-wrap {
  background-color: $brand-popup-bg;
  border: 1px solid $brand-popup-border;
}

.moodle-dialogue-base .moodle-dialogue-wrap .moodle-dialogue-hd {
  border-bottom: 1px solid $brand-divider;
}

//Align heading and close button
.moodle-dialogue-hd {
  h3 {
    margin-bottom: 0;
    line-height: 1.3;
  }
}

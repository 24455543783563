.dashboard-card {
  background-color: transparent;
  border: 0;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.dashboard-card__link {
  border-radius: 10px;
  overflow: hidden;

  text-decoration: none;
  color: $brand-content-text;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba($white, 0.02);
  border: 1px solid rgba($white, 0.1);

  position: relative;

  &:hover {
    text-decoration: none;
    color: $brand-content-text;

    .dashboard-card__details {
      max-height: 92px;
      transform: translateY(0);
      opacity: 1;
      transition: max-height 0.6s $easeOutExpo, transform 0.6s $easeOutExpo,
        opacity 0.5s 0.1s ease-out;
    }

    .dashboard-card-img > div {
      opacity: 1;
      transform: translateY(5px);
    }

    .card-body {
      //shadow above body
      box-shadow: 0 -10px 80px 30px rgba($black, 0.5);
      transition: box-shadow 0.6s $easeOutExpo;

      //top shadow inside body
      &:before {
        opacity: 1;
      }
    }

    .dashboard-card__body-inner {
      //body background
      &:before {
        background-color: $brand-dashboard-card-bg;
        transform: scale(1.5, 1.2) translateY(0);
        transition: background-color 0.6s $easeOutExpo;
      }
    }

    .card-body span[title] {
      color: $primary;
    }
  }
}

.dashboard-card .dashboard-card-img {
  height: auto !important;
  border-radius: 0;
  position: relative;
  box-shadow: 0 10px 100px 5px $black;
  //background-color: $black;

  //aspect-ratio: 334 / 375;
  aspect-ratio: 334 / 355;

  @include media-breakpoint-down(lg) {
    //height: 355px !important;
    height: 425px !important;
  }

  @include media-breakpoint-down(sm) {
    height: auto !important;
  }

  @media screen and (max-width: 320px) {
    margin-bottom: 40%;
  }

  > div {
    background: no-repeat center/cover;

    width: 100%;
    height: calc(100% + 20px);
    object-fit: contain;
    opacity: 0.6;
    mask-image: linear-gradient(to bottom, red 48%, transparent 70%);
    transform: translateY(0);
    transition: transform 2s $easeOutExpo, opacity 0.5s ease-out;

    @include media-breakpoint-down(lg) {
      opacity: 1;
      transform: translateY(5px);
    }
  }
}

.dashboard-card .card-body {
  background-color: transparent;
  height: 100%;
  z-index: 1;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: transparent;

  //shadow above body
  box-shadow: 0 0px 80px 30px rgba($black, 0);

  //overflow: hidden;

  //top shadow inside body
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    box-shadow: 0 10px 50px 5px $black;
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }

  @include media-breakpoint-down(lg) {
    //shadow above body
    box-shadow: 0 -10px 80px 30px $black;
    transition: box-shadow 0.6s $easeOutExpo;

    //top shadow inside body
    &:before {
      opacity: 1;
    }
  }
}

.dashboard-card__body-inner {
  height: 100%;
  position: relative;

  //body background
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    z-index: -1;
    background-color: rgba($black, 0.1);
    border: 1px solid rgba($white, 0.1);
    transform: scale(1.5, 1.2);
    transition: background-color 0.5s 0.1s linear;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    //body background
    &:before {
      background-color: $brand-dashboard-card-bg;
      transform: scale(1.5, 1.2) translateY(0);
      transition: background-color 0.6s $easeOutExpo;
    }
  }
}

.dashboard-card__subtitle {
  font-size: 16px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  color: $gray-100;
}

.dashboard-card__details {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);

  transition: max-height 0.6s $easeOutExpo, transform 0.6s 0.2s $easeOutExpo,
    opacity 0.5s 0.1s ease-out;

  @include media-breakpoint-down(lg) {
    max-height: 92px;
    transform: translateY(0);
    opacity: 1;
  }
}

.dashboard-card .categoryname {
  font-size: 14px;
  text-transform: uppercase;
  color: $brand-text-gray !important;
}

.dashboard-card .card-body span[title] {
  font-size: 25px;
  font-weight: $font-weight-black;
  font-style: italic;
  color: $primary;
  transition: color 0.8s ease-out;

  @include media-breakpoint-down(lg) {
    color: $primary;
  }
}

.dashboard-card__summary {
  margin-top: 10px;
  flex: 1;
}

.dashboard-card__summary p {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $gray-100;
}

.dashboard-card__progress {
  margin-top: 10px;

  p {
    font-size: 12px;
    font-weight: $font-weight-semibold;
    color: $brand-course-in-progress;
    margin: 0;
  }

  &--0 {
    p {
      color: $brand-course-no-progress;
    }
    .dashboard-card__progress-line {
      background-color: $brand-course-no-progress;
    }
  }

  &--100 {
    p {
      color: $brand-course-complete;
    }
    .dashboard-card__progress-line {
      background-color: $brand-course-complete;
    }
  }
}

.dashboard-card__progress-track {
  width: 100%;
  height: 5px;
  margin-top: 5px;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $brand-course-track;
  }
}

.dashboard-card__progress-line {
  width: 100%;
  height: 100%;
  background-color: $brand-course-in-progress;
  transform: scaleX(0);
  transform-origin: left;
}

.dashboard-card__top-content {
  display: flex;
  justify-content: space-between;

  > * {
    //border: 1px solid red;
  }
}

.dashboard-card__main-badge {
  padding-left: 5px;
  flex: 0 0 calc(100px - 25px);
  position: relative;
  top: -30px;
  transform: scale(1.3);
  transform-origin: top right;

  @media (max-width: 374px) {
    transform: scale(1);
  }

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: right;
  }
}

/**
 * Moodle variables
 *
 * Variables written for Moodle specific components
 *
 * Please do not override any Bootstrap variables here, custom Bootstrap variable should go in
 * preset files instead.
 */

$state-success-bg: theme-color-level("success", -10) !default;
$state-success-border: theme-color-level("success", -9) !default;

$state-info-bg: theme-color-level("info", -10) !default;
$state-info-border: theme-color-level("info", -9) !default;

$state-warning-bg: theme-color-level("warning", -10) !default;
$state-warning-border: theme-color-level("warning", -10) !default;

$state-danger-bg: theme-color-level("danger", -10) !default;
$state-danger-border: theme-color-level("danger", -9) !default;

$primary-nav-padding-y: ($spacer * 0.25) !default;
$primary-nav-padding-x: ($spacer * 0.5) !default;

$navbar-height: 60px !default;
$stickyfooter-height: calc(max(96px, calc(#{$font-size-base} * 3))) !default;
$course-content-maxwidth: 830px !default;
$medium-content-maxwidth: 1120px !default;
$h5p-content-maxwidth: 960px !default;

$box-shadow-drawer-left: -0.25rem .25rem .8rem rgba($black, .025) !default;
$box-shadow-drawer-right: 0 .25rem .8rem rgba($black, .025) !default;

$moremenu-height: 60px !default;

$primary-light-background: theme-color-level('primary', -12) !default;
$primary-light-border: theme-color-level('primary', -2) !default;
$primary-light-color: $body-color;
$primary-light-hover: theme-color-level('primary', -10) !default;
$activity-iconcontainer-height: 52px;
$activity-iconcontainer-width: 52px;

$activity-icon-administration-bg: #5d63f6 !default;
$activity-icon-assessment-bg:     #eb66a2 !default;
$activity-icon-collaboration-bg:  #f7634d !default;
$activity-icon-communication-bg:  #11a676 !default;
$activity-icon-content-bg:        #399be2 !default;
$activity-icon-interface-bg:      #a378ff !default;

$activity-icon-colors: () !default;
$activity-icon-colors: map-merge(
    (
        "administration": $activity-icon-administration-bg,
        "assessment": $activity-icon-assessment-bg,
        "collaboration": $activity-icon-collaboration-bg,
        "communication": $activity-icon-communication-bg,
        "content": $activity-icon-content-bg,
        "interface": $activity-icon-interface-bg
    ),
    $activity-icon-colors
);

// Atto
$atto-content-wrap-bg:                   $white !default;
$atto-content-wrap-color:                #333 !default;
$atto-toolbar-bg:                        #f2f2f2 !default;
$atto-toolbar-border-color:              #ccc !default;
$atto-toolbar-group-bg:                  $white !default;
$atto-toolbar-group-border-bottom-color: #b3b3b3 !default;
$atto-toolbar-button-color:              $gray-700 !default;
$atto-toolbar-button-hover-bg:           #ebebeb !default;
$atto-toolbar-button-active-bg:          #dfdfdf !default;
$atto-toolbar-button-gradient-inner:     $white !default;
$atto-toolbar-button-gradient-outer:     #dfdfdf !default;
$atto-control-icon-bg:                   $white !default;
$atto-notif-info:                        #f2f2f2 !default;
$atto-notif-warning:                     #ffd700 !default;


// Backup restore
$backup-restore-wibbler-border-color:   $black !default;
$backup-restore-state0-bg:              #eee !default;
$backup-restore-state1-bg:              #ddd !default;
$backup-restore-state2-bg:              #ccc !default;
$backup-restore-state3-bg:              #bbb !default;
$backup-restore-state4-bg:              #aaa !default;
$backup-restore-state5-bg:              #999 !default;
$backup-restore-state6-bg:              #888 !default;
$backup-restore-state7-bg:              #777 !default;
$backup-restore-state8-bg:              #666 !default;
$backup-restore-state9-bg:              #555 !default;
$backup-restore-state10-bg:             #444 !default;
$backup-restore-state11-bg:             #333 !default;
$backup-restore-state12-bg:             #222 !default;


// Calendar
$calendar-month-clickable-bg:   #ededed !default;


// Chat
$chat-users-list-img-border-color: #ccc !default;


// Core
$yui-overlay-bg:               #ffee69 !default;
$yui-overlay-color:            $black !default;
$yui-overlay-border-color:     #a6982b !default;
$yui-overlay-border-top-color: #d4c237 !default;

$comment-meta-color: gray !default;
$comment-paging-current-border-color: #ccc !default;

$tags-tagarea-border-color:             #e3e3e3 !default;
$tags-tagarea-title-color:              #999 !default;
$tags-tagarea-title-border-color:       #e3e3e3 !default;
$tags-tagarea-title-text-shadow-color:  rgba($white, .5) !default;

$webservice-doc-td-border-color: $black !default;

$userenrolment-link-hover-border-color: #666 !default;

$corelightbox-bg: #ccc !default;

$dialogue-base-bg:              $white !default;
$dialogue-base-border-color:    #ccc !default;
$dialogue-base-hd-border-color: #dee2e6 !default;

$dialogue-exception-label-bg:           #eee !default;
$dialogue-exception-label-border-color: #ccc !default;
$dialogue-exception-pre-bg:             $white !default;
$dialogue-exception-pre-border-color:   #ccc !default;
$dialogue-exception-file-color:         navy !default;
$dialogue-exception-call-color:         #333 !default;
$dialogue-exception-call-border-color:  #eee !default;

$dialogue-lightbox-bg: $white !default;

$chooserdialogue-bg:                    #f2f2f2 !default;
$chooserdialogue-options-border-color:  #bbb !default;

$choosercontainer-label-border-color:       $white !default;
$choosercontainer-instruction-bg:           $white !default;
$choosercontainer-chooseform-selected-bg:   $white !default;

$formlisting-bg:            $white !default;
$formlisting-border-color:  #ddd !default;

$formlistingmore-bg:            whitesmoke !default;
$formlistingmore-border-color:  #ddd !default;
$formlistingmore-color:         #9da0a4 !default;

$formlistingrow-bg:             #f7f7f9 !default;
$formlistingrow-border-color:   #e1e1e8 !default;

$criteria-desc-bg:              #f9f9f9 !default;
$criteria-desc-border-color:    #eee !default;

$hover-tooltip-bg:               $white !default;
$hover-tooltip-border-color:     rgba($black, .2) !default;
$hover-tooltip-border-top-color: $white !default;

$sr-only-active-bg: $white !default;

$overlay-icon-bg:    rgba($white, .6) !default;
$overlay-preview-bg: rgba($white, .8) !default;


// Course
$course-cat-without-actions-color: #333 !default;
$course-listing-color:             #a1a1a8 !default;


// Debug
$phpinfo-color:        $black !default;
$phpinfo-bg:           #ccc !default;
$phpinfo-border-color: $black !default;
$phpinfo-e-bg:         #ccf !default;
$phpinfo-h-bg:         #99c !default;


// Filemanager
$filemanager-panel-button-bg:               $white !default;
$filemanager-panel-button-shadow:           #999 !default;
$filemanager-picker-bg:                     $white !default;
$filemanager-picker-active-bg:              #f2f2f2 !default;
$filemanager-thumbnail-border-color:        $white !default;
$filemanager-thumbnail-img-border-color:    #ddd !default;
$filemanager-thumbnail-shadow:              #ccc !default;
$filemanager-thumbnail-over-bg:             $white !default;
$filemanager-thumbnail-hover-border-color:  #ddd !default;
$filemanager-thumbnail-hover-shadow:        #ccc !default;
$filemanager-filename-bg:                   $white !default;
$filemanager-picker-table-border-color:     #bbb !default;
$filemanager-ygtvfocus-bg:                  #eee !default;
$filemanager-picker-thumbnail-bg:           $white !default;
$filemanager-picker-thumbnail-border-color: #ddd !default;
$filemanager-item-disabled-bg:              #ebebe4 !default;

$filemanager-yui-table-border-color:               #bbb !default;
$filemanager-yui-table-header-bg:                  $white !default;
$filemanager-yui-table-header-border-bottom-color: #ccc !default;
$filemanager-yui-table-header-border-left-color:   $white !default;
$filemanager-yui-table-header-color:               #555 !default;
$filemanager-yui-table-cell-odd-bg:                #f6f6f6 !default;
$filemanager-yui-table-cell-odd-border-color:      #f6f6f6 !default;
$filemanager-yui-table-cell-even-bg:               $white !default;
$filemanager-yui-table-cell-even-border-color:     $white !default;

$filemanager-dnd-border-color:               #bbb !default;
$filemanager-dnd-upload-target-bg:           $white !default;
$filemanager-dnd-upload-target-border-color: #fb7979 !default;
$filemanager-dnd-upload-target-shadow:       $white !default;
$filemanager-dnd-upload-over-bg:             $white !default;
$filemanager-dnd-upload-over-border-color:   #6c8cd3 !default;

$filemanager-select-bg:           #f9f9f9 !default;
$filemanager-select-border-color: #bbb !default;


// Forms
$forms-autocomplete-bg:                   $white !default;
$forms-adminsettings-border-bottom-color: #e5e5e5 !default;


// Grade
$grade-manage-action-border-color:           #aaa !default;
$grade-criterion-level-hover-bg:             #dff0d8 !default;
$grade-criterion-level-currentchecked-bg:    #fff0f0 !default;
$grade-criterion-level-checked-bg:           #d0ffd0 !default;
$grade-criterion-level-checked-border-color: #555 !default;
$grade-criterion-level-delete-bg:            #ddd !default;
$grade-table-td-bg:                          $white !default;
$grade-table-toggle-icon-color:              #1d2125 !default;
$grade-badge-bg:                             #ced4da !default;
$grade-badge-color:                          #1d2125 !default;
$grade-search-hover-color:                   $white !default;
$grade-search-container-border-color:        #dee2e6 !default;


// Message
$message-selected-bg:                   #4f94cd !default;
$message-selected-color:                $white !default;
$message-preference-table-border-color: #ddd !default;
$message-notif-area-border-color:       #e3e3e3 !default;
$message-notif-area-timestamp-color:    #666 !default;
$message-clickable-hover-shadow:        rgba($black, .3) !default;
$message-loading-bg:                    rgba($white, 0.5) !default;
$message-drawer-shadow:                 rgba($black, .08) !default;


// Modules
$modules-badge-bg:                #f6f6f6 !default;
$modules-badge-color:             #5b5b5b !default;
$modules-rating-aggregate-bg:     #f6f6f6 !default;
$modules-rating-aggregate-color:  #5b5b5b !default;
$modules-forum-muted-color:       #707070 !default;
$modules-forum-post-border-color: #dee2e6 !default;
$modules-highlight-bg:            rgba(0, 123, 255, 0.5) !default;


// Moodlenet
$moodlenet-share-notice-bg: #f8f9fa !default;


// Popover
$popover-standard-border-color:             #ddd !default;
$popover-region-toggle-border-bottom-color: $white !default;
$popover-count-color:                       $white !default;
$popover-region-container-bg:               $white !default;


// Question
$question-choice-bg:                 #f2f2f2 !default;
$question-choice-color:              #333 !default;
$question-choice-text-shadow:        $white !default;
$question-choice-shadow:             #666 !default;
$question-border-color:              #ccc !default;
$question-border-bottom-color:       #bbb !default;
$question-gradient-start-color:      $white !default;
$question-gradient-end-color:        #ccc !default;
$question-type-bg:                   $white !default;
$question-type-shadow:               #ccc !default;
$question-import-error-border-color: #555 !default;
$question-bank-header-color:         #444 !default;
$question-quiz-edit-border-color:    #ddd !default;


// Report builder
$report-audience-border-color: rgba($black, .125) !default;


// User
$user-loading-icon-bg: rgba($white, 0.7) !default;

.modal {
    .modal-body {
        & > .loading-icon {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;

            .icon {
                position: absolute;
                top: 50%;
                /*rtl:ignore*/
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    // Override Bootstrap .close for better accessibility.
    .close {
        // Adjust the margins so the focus outline does not look clipped.
        margin: -0.8rem -0.8rem -0.8rem auto;

        // Inherit the opacity when focus is received for better focus outline contrast.
        &:not(:disabled):not(.disabled) {
            @include hover-focus() {
                opacity: inherit;
            }
        }
    }
}

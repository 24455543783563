ul.badges,
.node_category ul.badges {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  //border: 1px solid blue;
  margin: -15px;
  padding: 0;
  overflow: hidden;
  width: 100%;

  > * {
    //border: 1px solid red;
    width: 25%;
    padding: 15px;

    @include media-breakpoint-down(sm) {
      width: 50%;
    }
  }

  .badge-name {
    color: $body-color;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      text-decoration: underline;
    }
  }

  > li a {
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  > li a:focus {
    box-shadow: none;
    outline: none;
    color: $body-color;
    background-color: transparent;
  }
}

//Dashboard badges
&#page-my-index ul.badges {
  margin: auto;

  > * {
    width: 33.333333%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  //Filter
  > *:not(:nth-child(-n + 3)) {
    display: none;
  }
}
/* admin.less */
.formtable tbody th {
    font-weight: normal;
    text-align: right;
}

.path-admin #assignrole {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.path-admin .admintable .leftalign {
    text-align: left;
}

.environmenttable {
    .warn {
        @extend .alert-warning;
    }

    .error {
        @extend .alert-danger;
    }

    .ok {
        @extend .alert-success;
    }
}

.path-admin .admintable.environmenttable .name,
.path-admin .admintable.environmenttable .info,
.path-admin #assignrole .admintable .role,
.path-admin #assignrole .admintable .userrole,
.path-admin #assignrole .admintable .roleholder {
    white-space: nowrap;
}

.path-admin .incompatibleblockstable td.c0 {
    font-weight: bold;
}

#page-admin-course-category .addcategory {
    padding: 10px;
}

#page-admin-course-index .editcourse {
    margin: 20px auto;
}

#page-admin-course-index .editcourse th,
#page-admin-course-index .editcourse td {
    padding-left: 10px;
    padding-right: 10px;
}

.timewarninghidden {
    display: none;
}

#page-admin-qtypes #qtypes div,
#page-admin-qtypes #qtypes form,
#page-admin-qbehaviours #qbehaviours div,
#page-admin-qbehaviours #qbehaviours form {
    display: inline;
}

#page-admin-qtypes #qtypes img.spacer,
#page-admin-qbehaviours #qbehaviours img.spacer {
    width: 16px;
}

#page-admin-qbehaviours .cell.c3,
#page-admin-qtypes .cell.c3 {
    font-size: $font-size-sm;
}

#page-admin-lang .generalbox,
#page-admin-course-index .singlebutton,
#page-admin-course-index .addcategory,
#page-course-index .buttons,
#page-course-index-category .buttons,
#page-admin-course-category .addcategory,
#page-admin-stickyblocks .generalbox,
#page-admin-maintenance .buttons,
#page-admin-course-index .buttons,
#page-admin-course-category .buttons,
#page-admin-index .copyright,
#page-admin-index .copyrightnotice,
#page-admin-index .adminerror .singlebutton,
#page-admin-index .adminwarning .singlebutton,
#page-admin-index #layout-table .singlebutton {
    text-align: center;
    margin-bottom: 1em;
}

.path-admin-roles .capabilitysearchui {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: $spacer;
}

#page-admin-roles-define .topfields {
    margin: 1em 0 2em;
}

#page-admin-roles-override .capcurrent,
#page-admin-roles-define .capdefault {
    background-color: $table-hover-bg;
}

#page-filter-manage .backlink,
.path-admin-roles .backlink {
    margin-top: 1em;
}

#page-admin-roles-explain #chooseuser h3,
#page-admin-roles-usersroles .contextname {
    margin-top: 0;
}

#page-admin-roles-explain #chooseusersubmit {
    margin-top: 0;
    text-align: center;
}

#page-admin-roles-usersroles p {
    margin: 0;
}

#page-admin-roles-override .cell.c1,
#page-admin-roles-assign .cell.c3,
#page-admin-roles-assign .cell.c1 {
    padding-top: 0.75em;
}

#page-admin-roles-override .overridenotice,
#page-admin-roles-define .definenotice {
    margin: 1em 10% 2em 10%;
    text-align: left;
}

#page-admin-index .adminwarning.availableupdatesinfo .moodleupdateinfo span {
    display: block;
}

#page-admin-index .updateplugin div {
    margin-bottom: 0.5em;
}

#page-admin-user-user_bulk #users .fgroup {
    white-space: nowrap;
}

#page-admin-report-stats-index .graph {
    text-align: center;
    margin-bottom: 1em;
}

#page-admin-report-courseoverview-index .graph {
    text-align: center;
    margin-bottom: 1em;
}

#page-admin-lang .translator {
    border-width: 1px;
    border-style: solid;
}

// This is the CSS for the role assignment control.
.path-admin .roleassigntable {
    width: 100%;
}

.path-admin .roleassigntable td {
    vertical-align: top;
    padding: 0.2em 0.3em;
}

.path-admin .roleassigntable p {
    text-align: left;
    margin: 0.2em 0;
}

.path-admin .roleassigntable #existingcell,
.path-admin .roleassigntable #potentialcell {
    width: 42%;
}

// Targetting the label at the top.
.path-admin .roleassigntable #existingcell p > label:first-child,
.path-admin .roleassigntable #potentialcell p > label:first-child {
    font-weight: bold;
}

.path-admin .roleassigntable #buttonscell {
    width: 16%;
}

.path-admin .roleassigntable #buttonscell #assignoptions {
    font-size: $font-size-sm;
}

.path-admin .roleassigntable #removeselect_wrapper,
.path-admin .roleassigntable #addselect_wrapper {
    width: 100%;
}

.path-admin table.rolecap tr.rolecap th {
    text-align: left;
    font-weight: normal;
}

.path-admin .rolecap .hiddenrow {
    display: none;
}

.path-admin #defineroletable {
    .rolecap {
        .inherit,
        .allow,
        .prevent,
        .prohibit {
            text-align: center;
            padding: 0;
            min-width: 3.5em;
        }
    }
}

.path-admin .rolecap .cap-name,
.path-admin .rolecap .note {
    display: block;
    font-size: $font-size-sm;
    white-space: nowrap;
    font-weight: normal;
}

.path-admin .rolecap label {
    display: block;
    text-align: center;
    padding: 0.5em;
    margin: 0;
}

.path-admin,
.format-site {
    .header-maxwidth,
    .secondary-navigation .navigation .nav-tabs {
        max-width: none;
    }
}
.path-admin.path-admin-roles:not(.format-site),
.path-admin.path-admin-tool-lp {
    .header-maxwidth,
    .secondary-navigation .navigation .nav-tabs {
        max-width: $course-content-maxwidth;
    }
}

.plugincheckwrapper {
    width: 100%;
}

.environmentbox {
    margin-top: 1em;
}

#mnetconfig table {
    margin-left: auto;
    margin-right: auto;
}

.environmenttable .cell {
    padding: .15em .5em;
}

#trustedhosts .generaltable {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
}

#trustedhosts .standard {
    width: auto;
}

// This usage of legend is a bit weird,
// seems to be using them as error text
// that's only sometimes visible. Should
// look into sorting it.
#adminsettings legend {
    display: none;
}

#adminsettings fieldset.error {
    margin: .2em 0 .5em 0;
}

#adminsettings fieldset.error legend {
    display: block;
}

#admin-spelllanguagelist textarea {
    /* rtl:ignore */
    text-align: left;
    /* rtl:ignore */
    direction: ltr;
}

/* Styles for flags on admin settings */
.adminsettingsflags {
    float: right;
}

.adminsettingsflags label {
    margin-right: 7px;
}

.form-description pre,
.formsettingheading pre {
    /*rtl:ignore*/
    direction: ltr;
}

.form-item .form-setting .form-htmlarea {
    display: inline;
}

.form-item .form-setting .form-htmlarea .htmlarea {
    width: 640px;
    display: block;
}

.form-item .form-setting .form-multicheckbox ul {
    list-style: none;
    padding: 0;
    margin: 7px 0 0 0;
}

.form-item .form-setting .defaultsnext {
    display: inline;
}

.form-item .form-setting .locked-checkbox {
    margin-right: 0.2em;
    margin-left: 0.5em;
    display: inline;
}

.form-item .form-setting .form-password .unmask,
.form-item .form-setting .form-defaultinfo {
    display: inline-block;
}

.form-item .form-setting .form-defaultinfo {
    max-width: 100%;
    word-wrap: break-word;
}

#admin-emoticons td input {
    width: 8em;
}

#admin-emoticons td.c0 input {
    width: 4em;
}

.admin_colourpicker,
.admin_colourpicker_preview {
    display: none;
}

.jsenabled .admin_colourpicker_preview {
    display: inline;
}

@include media-breakpoint-up(md) {
    .jsenabled .admin_colourpicker {
        display: block;
        height: 102px;
        width: 410px;
        margin-bottom: 10px;
        box-sizing: content-box;
    }
    .admin_colourpicker .colourdialogue {
        float: left;
        border: 1px solid $input-border-color;
    }
    .admin_colourpicker .previewcolour {
        border: 1px solid $input-border-color;
        margin-left: 301px;
    }

    .admin_colourpicker .currentcolour {
        border: 1px solid $input-border-color;
        margin-left: 301px;
        border-top-width: 0;
    }
}

@include media-breakpoint-down(sm) {
    .jsenabled .admin_colourpicker {
        height: 150px;
        margin-bottom: 10px;
        display: block;
        position: relative;
    }
    .admin_colourpicker .previewcolour {
        display: none;
    }
    .admin_colourpicker .currentcolour {
        position: absolute;
        border: 1px solid $border-color;
        top: 100px;
        left: 0;
    }

}
.admin_colourpicker .loadingicon {
    vertical-align: middle;
    margin-left: auto;
}

#page-admin-index #notice .checkforupdates {
    text-align: center;
}

// Plugins overview page at admin/plugins.php
#page-admin-plugins {
    #plugins-overview-panel {
        .info {
            display: inline-block;
            margin-right: 1em;
        }
    }

    .checkforupdates {
        margin: 10px 0;

        .singlebutton {
            margin: 5px 0;
            padding: 0;

            div,
            input {
                margin: 0 3px 0 0;
            }
        }
    }

    .updateavailableinstallall {
        margin: 5px 0;
        padding: 0;

        div,
        input {
            margin: 0 3px 5px 0;
        }
    }

    #plugins-control-panel {
        .status-missing td {
            background-color: $state-warning-bg;
        }

        .pluginname {
            .componentname {
                font-size: $font-size-sm;
                color: $text-muted;
                margin-left: 22px;
            }
        }

        .version {
            .versionnumber {
                font-size: $font-size-sm;
                color: $text-muted;
            }
        }

        .uninstall {
            a {
                color: $danger;
            }
        }

        .notes {
            .label {
                margin-right: 3px;
            }

            .requiredby {
                font-size: $font-size-sm;
                color: $text-muted;
            }
        }
    }
}


// Plugins check page displayed during upgrade.
#plugins-check-page {

    // Plugins check table.
    #plugins-check {
        .status-missing,
        .status-downgrade {
            td {
                background-color: $state-danger-bg;
            }
        }
    }
}

// Available plugin update notification.

#plugins-check-page,
#plugins-control-panel {
    .pluginupdateinfo {
        background-color: $state-info-bg;

        &.maturity50 {
            background-color: $state-danger-bg;
        }

        &.maturity100,
        &.maturity150 {
            background-color: $state-warning-bg;
        }
        padding: 5px;
        margin: 10px 0;

        @include border-radius(5px);

        .info {
            display: inline-block;
        }

        .separator:after {
            content: " | ";
        }

        .singlebutton {
            margin: 5px 0;
            padding: 0;

            div,
            input {
                margin: 0 3px 0 0;
            }
        }
    }
}

.plugins-management-confirm-buttons {
    > div {
        display: inline-block;
        margin: 1em 1em 1em 0;
    }

    .continue {
        padding: 0;

        div,
        input {
            margin: 0;
        }
    }
}

#page-admin-index .upgradepluginsinfo {
    text-align: center;
}

#page-admin-index .adminwarning.availableupdatesinfo .moodleupdateinfo .separator:after {
    content: " | ";
}

/** MNet networking */
#page-admin-mnet-peers .box.deletedhosts {
    margin-bottom: 1em;
    font-size: $font-size-sm;
}

#core-cache-plugin-summaries table,
#core-cache-store-summaries table {
    width: 100%;
}

#core-cache-lock-summary table,
#core-cache-definition-summaries table,
#core-cache-mode-mappings table {
    margin: 0 auto;
}

#core-cache-store-summaries .default-store td {
    font-style: italic;
}

#core-cache-rescan-definitions,
#core-cache-mode-mappings .edit-link,
#core-cache-lock-additional-actions .new-instance {
    margin-top: 0.5em;
    text-align: center;
}

.maintenancewarning {
    position: fixed;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: $zindex-dropdown;
}

.modal.modal-in-page {
    z-index: 0;
}

#page-admin-search {
    .adminpagetitle {
        margin-bottom: 0;
        border-bottom: none;
    }
    .adminpagepath {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0 0 1.5rem 0;
        li {
            + li:before {
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                content: "#{$breadcrumb-divider}";
            }
        }
    }
    @media (min-width: 576px) {
        .container {
            overflow-wrap: break-word;
        }
    }
}

#page-admin-tasklogs {
    .task-class {
        font-size: $font-size-sm;
        color: $gray-600;
    }
}

.path-admin-tool-uploaduser {
    .uuwarning {
        background-color: $state-warning-bg;
    }

    .uuerror {
        background-color: $state-danger-bg;
    }

    .uuinfo {
        background-color: $state-success-bg;
    }
}

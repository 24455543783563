&.pagelayout-incourse {
  //Hide bottom navigation
  .activity-navigation {
    display: none;
  }

  /*
  Quiz page
  */
  &#page-mod-quiz-attempt {
    //Hide back button
    .tertiary-navigation {
      display: none;
    }

    //Quiz layout
    form[action*="quiz"] > div {
      display: flex;
      flex-direction: column;
    }
  }

  /*
  Initial Quiz page
  */
  &#page-mod-quiz-view {
    .tertiary-navigation {
      display: flex;
      justify-content: center;
    }

    //Hide completion conditions
    #region-main > h2 {
      display: none;
    }
    .activity-header {
      display: none;
    }

    //Hide grading information
    .quizinfo {
      opacity: 0;
      display: none;
    }

    .quizinfo + h3 {
      margin-top: 80px;

      @include media-breakpoint-down(sm) {
        margin-top: 50px;
      }
    }
  }

  /*
  Quiz summary page
  */
  &#page-mod-quiz-summary {
    //Hide back button
    .tertiary-navigation {
      display: none;
    }

    //Hide duplicate heading
    .tertiary-navigation + h2 {
      display: none;
    }
  }
}

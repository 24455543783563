@use "sass:map";
@use "sass:math";

//Other vendors
@import '../../node_modules/simplelightbox/dist/simple-lightbox.min.css';

//Bootstrap and this Theme's variables
@import "variables";

html.user-theme{
  scroll-behavior: smooth;
}

body.user-theme {
  //Resets moodle styling for our child theme
  @import "scss/fontawesome/fontawesome";
  @import "scss/bootstrap/bootstrap";
  @import "scss/moodle";

  @import "mixins";
  @import "animations";
  @import "base";
  @import "fonts";
  @import "typography";

  //Layouts
  @import "layouts/main-inner";

  //Components
  @import "components/buttons";
  @import "components/navbar";
  @import "components/page-header";
  @import "components/welcome-section";
  @import "components/dashboard-card-deck";
  @import "components/dashboard-card";
  @import "components/block_myoverview";
  @import "components/badges";
  @import "components/block_news_items";
  @import "components/footer-popover";
  @import "components/dropdown";
  @import "components/popover-region";
  @import "components/message-app";
  @import "components/drawer";
  @import "components/course-header";
  @import "components/courseindex";
  @import "components/topics";
  @import "components/forms";
  @import "components/tables";
  @import "components/modal";
  @import "components/userprofile";
  @import "components/block-card";
  @import "components/moodle-dialogue";
  @import "components/filemanager";
  @import "components/filepicker";
  @import "components/page-context-header";
  @import "components/notification";
  @import "components/notifications";
  @import "components/usermenu";
  @import "components/footer";
  @import "components/breadcrumb";
  @import "components/badge-svg";
  @import "components/loading-placeholder-content";
  @import "components/pagination";
  @import "components/forumpost";
  @import "components/popover";
  @import "components/tm-card";
  @import "components/news";
  @import "components/gallery";
  @import "components/block_featured";
  @import "components/block";
  @import "components/performance-bars";
  @import "components/loginform";
  @import "components/signupform";

  //Components: Question
  @import "components/question/question";
  @import "components/question/types/multiple-choice";
  @import "components/question/types/multiple-choice-image";
  @import "components/question/types/shortanswer";

  //Pages
  @import "pages/pagelayout-mydashboard";
  @import "pages/pagelayout-course";
  @import "pages/pagelayout-incourse";
  @import "pages/page-user-profile";
  @import "pages/page-user-edit";
  @import "pages/page-badges-badge";
  @import "pages/page-login";
  @import "pages/page-mod-forum-view";
  @import "pages/page-user-preferences";
  @import "pages/page-badges-mybadges";
  @import "pages/page";
  @import "pages/page-mod-forum-discuss";

  //Hidden Functions
  @import "hidden-features.scss";

  //Vendor Overrides
  @import "vendors/simplelightbox.scss";

  @import "debug.scss";
}

body:not(.user-theme) {
  @import "admin.scss";
}
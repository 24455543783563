.drawer.drawer-left {
  background-color: $brand-left-sidebar-bg;
  border-right: 1px solid $brand-divider;

  .drawercontent {
    padding-left: 0;
    padding-right: 0;
    background-color: rgba($black, 0.2);
  }

  .fa.fa-circle-thin {
    font-weight: $font-weight-normal;
  }

  .btn.drawertoggle[data-action='closedrawer']:focus {
    box-shadow: none;
  }

  //Disable ugly focus on sections
  *[role='treeitem']:not([aria-expanded='true']) {
    &:focus {
      box-shadow: none;
    }
  }

  .list-group-item {
    @include media-breakpoint-down(sm) {
      font-weight: $font-weight-semibold;
      border: 0;

      + .list-group-item {
        border-top: 1px solid $brand-divider;
      }
    }
  }

  .list-group-item:not(.active) {
    @include media-breakpoint-down(sm) {
      background-color: transparent;
      color: $white;
    }
  }

  .list-group-item.active {
    @include media-breakpoint-down(sm) {
      color: $white;
    }
  }
}

.drawer-toggles .drawer-left-toggle {
  @include media-breakpoint-down(sm) {
    top: calc(100vh - 70px);
  }
}

.drawer-toggles .drawer-left-toggle .btn {
  background-color: $drawer-toggle-bg;
  color: $drawer-toggle-icon-color;

  @include media-breakpoint-down(sm) {
    padding: 9px 12px 10px 8px;
  }

  .icon {
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

#page.drawers.show-drawer-right {
  margin-right: 0;
}
#page.drawers.show-drawer-left.show-drawer-right {
  margin-right: 0;
}
.drawer.drawer-left #courseindexdrawercontrols .btn[data-toggle='dropdown'] {
  color: $white;
}

//Hide courseindex in quiz pages
&.pagelayout-incourse {
  .drawer.drawer-left,
  .drawer-toggles .drawer-left-toggle {
    display: none;
  }

  #page.drawers.show-drawer-left {
    margin-left: 0;
  }
}

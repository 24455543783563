/* reports.less */

// The home for small tweaks to reports that don't require
// changes drastic enough to pull in the full module css
// and replace it completely.

#page-report-participation-index .participationselectform div label { // Using 'div' here to override the report styles.css
    display: inline-block;
    margin: 0 5px; // No top and bottom margin with a 5px left and right for LTR and RTL.
}

#page-report-participation-index .participationselectform div label[for=menuinstanceid] {
    margin-left: 0; // No left margin for LTR.
}

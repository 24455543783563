& {
  font-family: $font-family-sans-serif;

  @include media-breakpoint-down(sm) {
    font-size: 0.875rem;
  }
}

h1,
h2,
h3 {
  font-weight: $font-weight-black;
  font-style: italic;
  text-transform: uppercase;
}

h1 {
  font-size: 50px;

  @include media-breakpoint-down(sm) {
    font-size: 40px;
  }
}

h2 {
  font-size: 40px;

  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}

h3 {
  color: $primary;
}

//Page level heading overrides
#page-content div[role='main'] > h2 {
  margin-bottom: 20px;
}

//Form group headings
fieldset h3 {
  color: $primary;
}

//Headings with tooltip icons
h2:has(.btn[data-toggle='popover']) {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

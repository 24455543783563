.loginform {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loginform__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}

.loginform__heading {
  @include heading-display-medium;

  font-family: $headings-font-family;
  text-transform: uppercase;
  color: $primary;
  text-align: center;
  font-size: 3.75rem;

  @include media-breakpoint-down(sm) {
    font-size: 40px;
  }

  span {
    display: block;
  }

  span:nth-child(1) {
    font-size: 20px;
    color: $body-color;
    margin-bottom: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  span:nth-child(3) {
    color: $black;
    margin-top: 5px;
  }
}

.loginform__logo-image {
  font-size: 0;

  img {
    object-fit: contain;
    width: 300px;
    height: auto;
    transform: translateX(7px);

    @include media-breakpoint-down(sm) {
      width: 200px;
    }
  }
}

.loginform__meta {
  font-weight: bold;
  color: $brand-text-black;
  text-transform: uppercase;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.loginform__region {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.loginform__region i {
  display: inline-block;
  width: 1em;
  aspect-ratio: 1;
  transform: translateY(-1px);
}

.loginform .login-form {
  margin-top: 30px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
  }
}

.loginform input {
  background-color: rgba($white, 0.5);
  border-color: $brand-text-black;
  color: $brand-text-black;
  font-size: 18px;

  &::placeholder {
    border-color: rgba($black, 0.1);
  }
}

.loginform button.btn {
  width: 100%;
}

.loginform #loginbtn {
  margin-top: 20px;
}

.loginform .login-divider {
  border-color: $brand-divider;
  width: 100%;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

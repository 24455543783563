section.block.card.block_html {
  display: block;
  border: 0;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;

  > .card-body {
    padding: 0 !important;
  }

  .card-text.content {
    margin-top: 0 !important;
  }

  p:has(img) {
    margin-bottom: 0;
  }
}

section.block.card:not(.block_html),
section.userprofile__block.card,
&#page-user-preferences .card,
&#page-admin-tool-dataprivacy-summary .card {
  background-color: rgba($white, 0.05);
  border: 1px solid rgba($white, 0.1);
  border-radius: 0;
  color: $brand-content-text;
  padding: 0;

  > .card-body {
    padding: 0 10px 10px !important;
  }

  > .card-body .card-text {
    margin-top: 0 !important;
  }

  > .card-body .card-title,
  > .card-body .lead,
  > .card-header h3 {
    font-size: 14px;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
    color: $white;
    background-color: rgba($white, 0.1);
    display: inline-block !important;
    padding: 10px 20px;
    transform: translate(-10px, 0px);
  }

  > .card-body .card-title {
    display: inline-flex !important;
    gap: 10px;
    align-items: center;
  }

  > .card-body .card-title i {
    transform: translateY(-2px);
  }
}

&.pagelayout-mydashboard section.block.card {
  background-color: transparent;
}

section.block.block_news_items.card,
section.block.block_badges.card {
  border-color: $primary;

  > .card-body .card-title {
    background-color: $primary;
    color: rgba($white, 0.9);
    font-weight: $font-weight-semibold;
  }
}

&#page-admin-tool-dataprivacy-summary .card {
  > .card-header {
    padding: 0 10px 10px !important;
    background-color: transparent;
    border: 0;
  }

  > .card-body h4 {
    font-size: 18px;
    color: $primary;
    text-transform: uppercase;
  }
}

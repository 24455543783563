@keyframes fade-end {
  to {
    opacity: 1;
  }
}
@keyframes fade-out-end {
  to {
    opacity: 0;
  }
}
@keyframes translate-end {
  to {
    transform: translate(0);
  }
}
@keyframes scale-end {
  to {
    transform: scale(1);
  }
}

@mixin fade-in($duration, $delay, $easing) {
  will-change: transform;
  opacity: 0;
  animation: $duration ease-out $delay forwards fade-end;
}

@mixin fade-out($duration, $delay, $easing) {
  will-change: transform;
  opacity: 1;
  animation: $duration ease-out $delay forwards fade-out-end;
}

@mixin fade-in-slide-y($duration, $delay, $easing, $translateStart) {
  will-change: transform;
  opacity: 0;
  transform: translateY($translateStart);
  animation: $duration $easing $delay forwards translate-end,
    $duration ease-out $delay forwards fade-end;
}

@mixin fade-in-slide-x($duration, $delay, $easing, $translateStart) {
  will-change: transform, opacity;
  opacity: 0;
  transform: translateX($translateStart);
  animation: $duration $easing $delay forwards translate-end,
    $duration ease-out $delay forwards fade-end;
}

@mixin slide-x($duration, $delay, $easing, $translateStart) {
  will-change: transform;
  transform: translateX($translateStart);
  animation: $duration $easing $delay forwards translate-end;
}

@mixin scale-in-x($duration, $delay, $easing, $scaleStart) {
  will-change: transform;
  transform: scaleX($scaleStart);
  animation: $duration $easing $delay forwards scale-end;
}

@mixin fade-in-scale($duration, $delay, $easing, $scaleStart) {
  will-change: transform, opacity;
  opacity: 0;
  transform: scale($scaleStart);
  animation: $duration $easing $delay forwards scale-end,
    $duration ease-out $delay forwards fade-end;
}

@mixin slide-y($duration, $delay, $easing, $translateStart) {
  will-change: transform;
  transform: translateY($translateStart);
  animation: $duration $easing $delay forwards translate-end;
}

@mixin text-fade-slide-y($duration, $delay, $translateStart) {
  will-change: transform, opacity;
  opacity: 0;
  transform: translateY($translateStart);
  animation: $duration $easeOutExpo $delay forwards translate-end,
    ($duration + 0.1s) ease-out ($delay + 0.1) forwards fade-end;
}

@mixin pop-in($duration, $delay) {
  will-change: transform, opacity;
  opacity: 0;
  transform: scale(0);
  animation: $duration $easeOutBack $delay forwards scale-end,
    $duration ease-out $delay forwards fade-end;
}

//Intro animation for Dashboard Page
&#page-my-index {
  .welcome-section__heading-small {
    @include fade-in-slide-y(0.8s, 1s, $easeOutExpo, -30px);
  }

  .welcome-section__heading-big {
    > span {
      overflow: hidden;

      span {
        display: block;
        @include fade-in-slide-y(0.8s, 1s, $easeOutExpo, 100%);
      }
    }
  }

  //borders
  .welcome-section:after {
    transform-origin: right;
    @include scale-in-x(3s, 0.5s, $easeOutExpo, 0);
  }
  .navbar.fixed-top:after {
    transform-origin: left;
    @include scale-in-x(3s, 0.5s, $easeOutExpo, 0);
  }

  //background
  .welcome-section:before {
    @include fade-out(1s, 1s, ease-out);
  }

  //image
  .welcome-section__image {
    //@include fade-in(1s, 2s, ease-out);
    @include fade-in-scale(0.7s, 2s, $easeOutExpo, 0.95);
  }

  //description
  .welcome-section__heading + p {
    @include text-fade-slide-y(0.8s, 1.5s, 20px);
  }
}

//Intro animation for Course Content Page
&#page-course-view-topics {
  .course-header__image {
    @include fade-in-slide-y(2s, 0.5s, $easeOutExpo, 30px);
  }

  .course-header__header {
    @include fade-in-slide-x(1s, 1s, $easeOutExpo, 300px);
  }

  //summary section
  .topics .course-section:first-child {
    //borders
    &:after {
      @include slide-x(3s, 0.5s, $easeOutExpo, -3000px);
    }
    //bg
    &:before {
      @include fade-in(0.5s, 1s, ease-out);
    }

    //badges
    .activity-altcontent .row .col:nth-child(1) {
      @for $i from 1 through 5 {
        img:nth-child(#{$i}) {
          @include pop-in(0.5s, 1s + (0.5 * $i));
        }
      }
    }

    //text content
    .activity-altcontent .row .col:nth-child(2) {
      @include text-fade-slide-y(1s, 1.5s, 20px);
    }
  }
}

// //Simple fade-in for unearned badges
// &#page-user-profile{
//   a:has(.badge-svg--unearned){    
//     @include fade-in(3s, 1s, ease-out);
//   }
// }
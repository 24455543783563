.dashboard-card-deck {
  display: flex;
  margin: -20px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  > * {
    flex: 0 0 33.33333% !important;
    margin: 0 !important;
    padding: 20px;

    @include media-breakpoint-down(md) {
      flex: 0 0 50% !important;
    }

    @include media-breakpoint-down(sm) {
      flex: 1 !important;
    }
  }
}

//Disable right sidebar toggle
&.pagelayout-course .drawer-right-toggle {
  display: none;
  pointer-events: none;
}

&.pagelayout-course {
  overflow-x: hidden;

  #page.drawers .main-content > .main-inner {
    padding-top: 0;
    margin-top: 0;
  }

  #page {
    padding-bottom: 50px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 25px;
    }
  }

  #page-content {
    margin-top: 50px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }
}
